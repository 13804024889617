import analytics from '@lib/gtm/analytics'

type CartAnalytics = {
  trackCartPage: () => void
  trackContinueShopping: () => void
  addUpsellCart: () => void
  trackCheckoutButtonMiniCart: () => void
  trackCheckoutButtonCart: () => void
}

const cartAnalytics: CartAnalytics = {
  trackCartPage: () => {
    analytics.track('Cart', 'Open Cart Page')
  },
  trackContinueShopping: () => {
    analytics.track('Cart', 'Continue Shopping')
  },
  addUpsellCart: () => {
    analytics.track('Cart', 'Added Cart Upsell')
  },
  trackCheckoutButtonMiniCart: () => {
    analytics.track('MiniCart', 'Checkout Button')
  },
  trackCheckoutButtonCart: () => {
    analytics.track('Cart', 'Checkout Button')
  }
}

export default cartAnalytics
