import Spinner from '@atoms/Spinner/Spinner'

import { formatCents } from '@utils/strings'
import { usePublisherContext } from '@concepts/Publisher/store/context'
import { Text } from '@lib/UIComponents'
import { TextProps } from '@lib/UIComponents/Text/Text'

type CurrencyProps = {
  children: number
  defaultTo?: string
  acceptsZero?: boolean
  monthly?: boolean
  loading?: boolean
} & TextProps

const isPositive = (value: number): boolean => value > 0

const Currency = ({
  children,
  loading,
  acceptsZero,
  monthly,
  defaultTo,
  ...props
}: CurrencyProps) => {
  const { currency } = usePublisherContext()

  const code = currency && currency.code !== 'USD' ? ` ${currency.code}` : ''

  const numberToDisplay =
    isPositive(children) || acceptsZero
      ? formatCents(children) + code
      : defaultTo

  return loading ? (
    <Spinner alt="Calculating..." />
  ) : (
    <Text {...props}>
      {numberToDisplay}
      {monthly && '/mo'}
    </Text>
  )
}

export default Currency
