import Container from '@atoms/UIContainer/Container'
import MiniCart from '@concepts/Cart/views/MiniCartPopup/MiniCart'
import { mergeDeepRight } from 'ramda'
import { UIStyles } from '@lib/UIComponents/types'
import { Box } from '@lib/UIComponents'

type Props = {
  useContainer: boolean
  sx?: UIStyles
  children: React.ReactNode
}

const Main = ({ children, useContainer, sx = {} }: Props) => {
  return (
    <Box as="main" role="main" sx={{ flex: 1 }}>
      {useContainer ? (
        <Container
          sx={mergeDeepRight(
            {
              px: 5,
              py: [4, 8, 16]
            },
            sx as object
          )}
        >
          {children}
        </Container>
      ) : (
        children
      )}

      <MiniCart />
    </Box>
  )
}

export default Main
