/* istanbul ignore file */
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { Box, SxStyleProp } from 'rebass'

import { Props } from '@concepts/Paypal/types/props'
import { Payment } from '@concepts/Paypal/types/payment'
import { Actions } from '@concepts/Paypal/types/actions'
import { ShippingPreference } from '@concepts/Paypal/types/address'
import { Address } from 'src/generated/graphql'
import { Cart } from '@concepts/Cart/types/cart'
import { Notyf } from 'notyf'

import { useNotyfContext } from '@atoms/Notyf/context'
import { usePaypal } from '@concepts/Paypal/hooks/usePaypal'
import { useScript } from '@concepts/Paypal/hooks/useScript'
import { useCartContext } from '@concepts/Cart/store/context'
import { useCheckoutContext } from '@concepts/Checkout/store/context'
import { usePublisherContext } from '@concepts/Publisher/store/context'

import ProcessShippingCosts from '@concepts/Paypal/app/ProcessShippingCosts'
import CreateOrder from '@concepts/Paypal/app/CreateOrder'

import { SOMETHING_WENT_WRONG_WITH_PAYPAL } from '@utils/defaultMessages'
import { Summary } from '@concepts/Cart/types/summary'

type CartUpdateType = {
  cart: Cart
  summary: Summary
}

const GATEWAY = 'PAYPAL'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    paypal: any
  }
}

const getShippingPreference = (
  address?: Address,
  cart?: Cart
): ShippingPreference => {
  if (!cart?.shippable) return ShippingPreference.GET_FROM_FILE
  if (address) return ShippingPreference.NO_SHIPPING

  return ShippingPreference.GET_FROM_FILE
}

const PaypalPopupButton: React.FC<
  React.PropsWithChildren<Props & { sx?: SxStyleProp; disabled?: boolean }>
> = ({ sx = {}, disabled }) => {
  const [updatedCart, setUpdatedCart] = useState<CartUpdateType>()
  const { checkout } = usePaypal()
  const {
    cart,
    credit: { payEntirelyWithCredits },
    setCartShippingAddress,
    syncCartForCheckout
  } = useCartContext()
  const { summary } = cart
  const { softDescriptor } = usePublisherContext()
  const { isReady } = useScript({
    currency:
      updatedCart?.summary.currency?.toUpperCase() ??
      summary.currency?.toUpperCase(),
    shippable: cart.shippable
  })

  useEffect(() => {
    const updateCart = async () => {
      const cartUpdate: CartUpdateType = await syncCartForCheckout({})
      setUpdatedCart(cartUpdate)
    }

    updateCart()
  }, [syncCartForCheckout, cart])

  const notyf = useNotyfContext()

  const [
    {
      purchase: { address }
    },
    ,
  ] = useCheckoutContext()

  useEffect(() => {
    window.dispatchEvent(
      new CustomEvent('toggle_paypal', {
        detail: { disableButton: disabled || payEntirelyWithCredits }
      })
    )
  }, [payEntirelyWithCredits, disabled])

  if (!isReady) return null

  const createOrder = async (
    _: Payment,
    actions: Actions
  ): Promise<unknown> => {
    const cartUpdate: CartUpdateType = await syncCartForCheckout({})
    setUpdatedCart(cartUpdate)
    const breakdown = ProcessShippingCosts.getBreakdownItems(cartUpdate.summary)

    const shippingPreference = getShippingPreference(address, cartUpdate.cart)

    return CreateOrder.execute({
      type: CreateOrder.Type.REGULAR,
      actions,
      softDescriptor,
      getAttributes: () => ({
        cart: {
          ...cartUpdate.cart,
          summary: cartUpdate.summary
        },
        breakdown,
        shippingPreference
      })
    }).catch(() => {
      ;(notyf as Notyf).error({
        message: SOMETHING_WENT_WRONG_WITH_PAYPAL,
        duration: 0
      })
    })
  }

  const onShippingChange = ProcessShippingCosts.execute((address: Address) =>
    setCartShippingAddress(GATEWAY, address)
  )

  const onCancel = async (): Promise<void> =>
    setCartShippingAddress(GATEWAY, address || {})

  const Buttons = window.paypal.Buttons.driver('react', { React, ReactDOM })

  /* istanbul ignore next */
  const handleInit = (_: object, actions: Actions): void => {
    if (disabled) actions.disable()

    window.addEventListener(
      'toggle_paypal',
      (({ detail: { disableButton } }: CustomEvent) => {
        if (disableButton) {
          actions.disable()
        } else {
          actions.enable()
        }
      }) as EventListener,
      false
    )
  }

  return (
    <Box sx={{ ...sx, opacity: disabled ? '0.5' : null }}>
      <Buttons
        createOrder={createOrder}
        onShippingChange={onShippingChange}
        onApprove={checkout}
        onCancel={onCancel}
        style={{ height: 40 }}
        onInit={handleInit}
      />
    </Box>
  )
}

export default PaypalPopupButton
