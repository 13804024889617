import gql from 'graphql-tag'
import { pathOr } from 'ramda'
import { CartItemAttributes, AddCartItemPayload } from 'src/generated/graphql'
import { TApolloClient } from 'src/types/apollo'
import CartItemDTO from '@concepts/Cart/dto/CartItemDTO'
import { CartActionResult } from '@concepts/Cart/types/actions'
import { setCookie } from '@utils/cookies'

const ADD_CART_ITEM_MUTATION = gql`
  mutation addCartItem($input: AddCartItemInput!) {
    addCartItem(input: $input) {
      success
      encryptedCartId
      expiredSales

      cartItem {
        databaseId
        cartId
        title
        quantity
        unitPriceCents
        source
        itemListName

        sale {
          databaseId
          name
          priceInCents
          pictureAttributes
          slug
          isRecurringTrial
          recurringTrialPeriodDays
          maxPerUser
          retailPriceInCents

          category {
            name
          }

          saleGroupOption {
            databaseId
            saleGroupId
            name
          }
        }
      }

      errors {
        message
        path
      }
    }
  }
`

export const addToCartRepository = async (
  cartItemAttributes: CartItemAttributes,
  apolloClient: TApolloClient,
  encryptedCartId?: string
): Promise<CartActionResult> => {
  const result = await apolloClient.mutate({
    mutation: ADD_CART_ITEM_MUTATION,
    variables: {
      input: { cartItemAttributes, encryptedCartId }
    }
  })
  const addCartItem = pathOr(
    {},
    ['data', 'addCartItem'],
    result
  ) as AddCartItemPayload

  const cartItem = addCartItem.cartItem
    ? CartItemDTO.parse(addCartItem.cartItem)
    : null

  if (addCartItem.encryptedCartId) {
    setCookie('encrypted_cart_id', addCartItem.encryptedCartId)
  }

  return {
    ...addCartItem,
    cartItem
  } as CartActionResult
}
