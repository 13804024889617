import Overlay from '@atoms/Overlay/Overlay'
import display from '@utils/display'
import Loader from './Loader'
import { Box, Flex, Text } from '@lib/UIComponents'

const styles = {
  box: {
    zIndex: 'loader',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  container: {
    position: 'relative',
    zIndex: 'loader',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  overlay: {
    backgroundColor: 'background',
    opacity: 0.9
  },
  message: {
    fontSize: '1.5rem !important',
    fontWeight: 'bold',
    color: 'black'
  }
}

type FullLoaderProps = {
  message: string
  show: boolean
}

const FullLoader = ({ message, show }: FullLoaderProps) => {
  const showModal = display(show, 'block') as object

  return (
    <Box sx={{ ...styles.box, ...showModal }}>
      <Overlay sx={styles.overlay} />
      <Flex
        sx={{
          position: 'relative',
          zIndex: 'loader',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Text sx={styles.message}>{message}</Text>
        <Loader
          dotColor="black"
          lightenColor
          sx={{
            position: 'initial',
            marginTop: '22px',
            marginRight: '60px',
            transform: 'translate(0, -50%)'
          }}
        />
      </Flex>
    </Box>
  )
}

export default FullLoader
