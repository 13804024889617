import noop from '@utils/noop'
import Redirect from '@utils/redirect'
import { Cart } from '../types/cart'

export const handleRedirectToIndexPage = (cart: Cart) => {
  if (cart.items?.length === 0) {
    Redirect({
      pathname: '/',
      external: true
    })
  } else {
    noop()
  }
}
