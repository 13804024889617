import { OptionList, Option } from './types'

type Props = {
  optionsList: OptionList
  valueIdentifier: string
  labelIdentifier: string
}

const toLabelValue = (
  option: Option,
  labelIdentifier: string,
  valueIdentifier: string
) => {
  return typeof option === 'object'
    ? {
        label: option[labelIdentifier],
        value: option[valueIdentifier]
      }
    : {
        label: option,
        value: option
      }
}

const Options: React.FC<React.PropsWithChildren<Props>> = ({
  optionsList,
  labelIdentifier,
  valueIdentifier
}) => {
  return (
    <>
      {optionsList.map((option) => {
        const { label, value } = toLabelValue(
          option,
          labelIdentifier,
          valueIdentifier
        )

        return (
          <option key={value} value={value}>
            {label}
          </option>
        )
      })}
    </>
  )
}

export default Options
