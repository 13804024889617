import Card from '@atoms/UICard/Card'
import { Text } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'

type Props = {
  message?: string
  children?: React.ReactNode
  sx?: UIStyles
}

const Error = ({ message, sx = {}, children, ...props }: Props) =>
  message || children ? (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'row',
        mt: 2,
        py: 2,
        px: [3, 4],
        border: 0,
        bg: 'dangerLight',
        fontSize: ['xs', 'sm'],
        lineHeight: 1.1,
        color: 'danger',
        ...sx
      }}
      {...props}
    >
      {message && <Text sx={{ fontSize: ['sm', 'md'] }}>{message}</Text>}
      {children}
    </Card>
  ) : null

export default Error
