import { mergeDeepRight } from 'ramda'

import EmailRegister from '@molecules/EmailRegister/EmailRegister'
import { Box, Text } from '@lib/UIComponents'

type Orientations = 'row' | 'column'
type FormOrientation = Orientations | Orientations[]

export type NewsletterType = {
  headline: string
  subHeadline: string
  btnText: string
  analyticsName: string
  formOrientation?: FormOrientation
  variant?: 'dark' | 'light'
}

const styles = (variant: string, formOrientation: FormOrientation) => {
  const defaultStyles = {
    mb: [8, 8, 8, 0],
    width: ['100%', '100%', '275px'],
    maxWidth: '460px',
    button: {
      px: '28px',
      fontSize: [1, 2]
    },
    p: {
      mb: 4,
      width: ['275px', '100%']
    },
    input: {
      borderRadius: 0,
      border: 'none',
      borderBottom: '2px solid',
      borderColor: 'grays.700',
      pl: 0,
      transition: 'border-color 250ms',
      '&:focus': {
        outline: 0,
        borderColor: 'white'
      }
    },
    span: {
      display: 'block',
      width: '275px',
      fontSize: 1,
      pt: 1
    },
    form: {
      display: 'flex',
      flexDirection: formOrientation
    }
  }

  const lightTheme = {
    width: '100%',
    py: 6,
    px: 8,
    border: '1px solid',
    borderColor: 'grays.150',
    borderRadius: 'default',
    backgroundColor: 'white',
    h4: {
      mb: 3
    },
    p: {
      color: 'grays.600',
      width: '100%',
      mb: 2
    },
    input: {
      pl: 0,
      borderColor: 'grays.600',
      borderWidth: 3,
      '&:focus': {
        borderColor: 'blacks.500'
      }
    },
    span: {
      color: 'grays.600'
    },
    button: {
      px: 8,
      fontSize: [1, 2],
      py: 4,
      textTransform: 'none',
      letterSpacing: 'default'
    }
  }

  return variant === 'dark'
    ? defaultStyles
    : mergeDeepRight(defaultStyles, lightTheme)
}

const Newsletter: React.FC<React.PropsWithChildren<NewsletterType>> = ({
  headline,
  subHeadline,
  btnText,
  analyticsName,
  variant = 'light',
  formOrientation = 'column'
}) => {
  return (
    <Box sx={styles(variant, formOrientation)}>
      <Text as="p" sx={{ fontSize: [4, '2xl'], fontWeight: 'bold' }}>
        {headline}
      </Text>

      <Text as="p">{subHeadline}</Text>

      <Box
        as="span"
        sx={{
          button: {
            mt: 4
          }
        }}
      >
        <EmailRegister
          btnText={btnText}
          analyticsName={analyticsName}
          variant="light"
        />
      </Box>
    </Box>
  )
}

export default Newsletter
