import { useReducer, useEffect, Reducer, useMemo } from 'react'

import { CartContext } from './context'

import { useApollo } from '@lib/apollo'
import { useGlobalContext } from '@config/contexts'

import { Cart, CartState } from '@concepts/Cart/types/cart'
import { initialState, reducer } from '@concepts/Cart/store/reducer'

import CurrentCartRepository from '@concepts/Cart/repository/CurrentCartRepository'
import { setCart, setLoading } from '@concepts/Cart/store/actions'
import { getCartIdFromCookies } from '@config/cookies'

import useCartOperations from '../hooks/useCartOperations'
import { Action } from '../types/actions'
import { getCookie } from '@utils/cookies'
import { COUPON_CODE_COOKIE_NAME } from '@config/env'

const Provider: React.FC<React.PropsWithChildren<unknown>> = ({ children }) => {
  const couponFromCookie = getCookie(COUPON_CODE_COOKIE_NAME)
  const apolloClient = useApollo()
  const encryptedCartId = getCartIdFromCookies()
  const [state, dispatch] = useReducer(reducer as Reducer<CartState, Action>, {
    ...initialState,
    coupons: {
      codes: couponFromCookie ? [{ code: couponFromCookie }] : []
    }
  })
  const { hostname } = useGlobalContext()

  const {
    addSaleToCart,
    updateCart,
    removeSaleFromCart,
    syncCartForCheckout,
    setCartItem,
    setCartShippingAddress
  } = useCartOperations(state, dispatch)

  const totalQuantity = (cart: Cart) => {
    return cart.items.reduce((acc, { quantity }) => acc + quantity, 0)
  }

  useEffect(() => {
    const setCurrentCart = async (): Promise<void> => {
      try {
        const currentCart = (await CurrentCartRepository.find(
          { encryptedCartId },
          apolloClient
        )) as Cart

        dispatch(setCart(currentCart))
      } finally {
        dispatch(setLoading(false))
      }
    }

    setCurrentCart()
  }, [hostname, apolloClient, encryptedCartId])

  const value = useMemo(
    () => ({
      state,
      cart: state.cart as Cart,
      dispatch,
      isLoading: state.isLoading as boolean,
      coupons: state.coupons,
      credit: state.credit,
      setCart,
      setCartItem,
      setCartShippingAddress,
      addSaleToCart,
      updateCart,
      removeSaleFromCart,
      syncCartForCheckout,
      cartProductsQuantity: totalQuantity(state.cart)
    }),
    [
      addSaleToCart,
      updateCart,
      removeSaleFromCart,
      syncCartForCheckout,
      dispatch,
      state,
      setCartItem,
      setCartShippingAddress
    ]
  )

  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}

export default Provider
