import Redirect from '@utils/redirect'
import { isTest } from '@utils/env'

type OrderRedirectParams = {
  id?: number | null | undefined
  gtoken?: string
  uidocp?: string
}

const orderRedirect = ({ id, gtoken, uidocp }: OrderRedirectParams): void => {
  if (!id) return

  let query = {}

  if (gtoken) {
    query = { gtoken }
  } else if (uidocp) {
    query = { uidocp }
  }

  const testEnv = isTest()

  setTimeout(
    () => {
      Redirect({
        pathname: `/orders/${id}`,
        external: !testEnv,
        query
      })
      // Guarantees all GTM events are called before redirecting
    },
    testEnv ? 0 : 300
  )
}

export default orderRedirect
