import { isClient } from '@utils/env'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { CartPopupContext } from './popupContext'

const Provider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [displayItemAddedMessage, setDisplayItemAddedMessage] = useState(false)
  const [navbarHeight, setNavbarHeight] = useState('')
  const [popUpMobileMaxHeight, setPopupMobileMaxHeight] = useState('auto')

  const handleNavBarPosition = useCallback(() => {
    const navbar = document.getElementById('header')
    if (!navbar) return

    const originalStyle = window.getComputedStyle(navbar)

    setNavbarHeight(originalStyle.height)
    setPopupMobileMaxHeight(
      `${window.innerHeight - parseInt(originalStyle.height)}px`
    )
  }, [])

  useEffect(() => {
    /* istanbul ignore next */
    if (isClient()) {
      handleNavBarPosition()
    }
  }, [handleNavBarPosition])

  const handleMiniCartPopup = useCallback(
    ({ isAddItem }: { isAddItem?: boolean }) => {
      if (isAddItem) setDisplayItemAddedMessage(!displayItemAddedMessage)
      if (!isOpen === false) setDisplayItemAddedMessage(false)

      setIsOpen(!isOpen)
      handleNavBarPosition()
    },
    [isOpen, displayItemAddedMessage, handleNavBarPosition]
  )

  const value = useMemo(
    () => ({
      isOpen,
      setIsOpen,
      displayItemAddedMessage,
      handleMiniCartPopup,
      handleNavBarPosition,
      navbarHeight,
      popUpMobileMaxHeight
    }),
    [
      isOpen,
      displayItemAddedMessage,
      handleMiniCartPopup,
      handleNavBarPosition,
      navbarHeight,
      popUpMobileMaxHeight
    ]
  )

  return (
    <CartPopupContext.Provider value={value}>
      {children}
    </CartPopupContext.Provider>
  )
}

export default Provider
