export const styles = {
  container: {
    position: 'relative'
  },
  selectBox: (hasError: boolean): object => ({
    width: '100%',
    select: {
      width: '100%',
      appearance: 'none',
      '::-ms-expand': {
        display: 'none'
      },
      cursor: 'pointer',
      backgroundColor: 'background',
      border: '1px solid',
      borderColor: hasError ? 'danger' : 'grays.100',
      borderRadius: '16px',
      font: 'inherit',
      margin: '0',
      paddingLeft: '10px',
      paddingRight: '25px',
      fontSize: [null, 2],
      '&:focus': {
        outline: 0,
        borderColor: hasError ? 'danger' : 'blacks.500'
      }
    },
    display: 'inline-block'
  }),
  label: (inline = false): object => ({
    ...(inline
      ? { mr: [0, 0, 3], mb: [2, 2, 0] }
      : {
          display: 'flex',
          width: '0',
          position: 'absolute',
          pointerEvents: 'none',
          fontSize: [null, 1],
          left: '10px',
          top: '50%',
          transform: 'translateY(-50%)'
        })
  }),
  arrowIcon: {
    width: '0',
    position: 'absolute',
    right: '22px',
    pointerEvents: 'none',
    fontSize: 1,
    top: '50%',
    transform: 'translateY(-35%)'
  }
}

export const defaultFormSelectStyle = {
  width: '100%',
  height: ['44px', '50px'],
  boxSizing: 'border-box',
  borderRadius: '5px',
  fontSize: [1, 2],
  paddingTop: ['18px', '21px'],
  paddingBottom: ['3px', '6px'],
  paddingRight: '30px',
  paddingLeft: 3,
  lineHeight: '21px',
  display: 'inline-block',
  overflow: 'hidden',
  transition: 'all 250ms',
  touchAction: 'manipulation',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '&:focus': {
    outline: 0
  },
  '&:hover': {
    outline: 0
  },
  '&::placeholder': {
    opacity: 0
  }
}
