import { useState, useEffect } from 'react'
import { PAYPAL_CLIENT_SDK_URL } from '@config/env'

const State = {
  loading: 'loading',
  idle: 'idle',
  ready: 'ready',
  error: 'error'
}

export type Options = {
  currency: string
  intent?: string
  isCreditCard?: boolean
  shippable: boolean
}

const getStateByEventType = (type: string): string =>
  type === 'load' ? State.ready : State.error

const getSource = ({
  currency,
  intent = 'authorize',
  isCreditCard = false,
  shippable
}: Options): { src: string; defer: boolean } => {
  const queryParams = [
    'currency=' + currency,
    'disable-funding=credit,card',
    'intent=' + intent,
    'components=messages,buttons,funding-eligibility'
  ]

  if (isCreditCard) queryParams.splice(1, 1)
  if (currency === 'USD' && !shippable) queryParams.push('enable-funding=venmo')

  return {
    src: PAYPAL_CLIENT_SDK_URL + '&' + queryParams.join('&'),
    defer: true
  }
}

const useScript = (options: Options): { isReady: boolean } => {
  const { currency } = options
  const { src } = getSource(options)

  const [status, setStatus] = useState(State.idle)

  useEffect(() => {
    if (!currency) return

    let script = document.querySelector(
      `script[src="${src}"]`
    ) as HTMLScriptElement

    if (!script) {
      script = document.createElement('script') as HTMLScriptElement

      script.src = src
      script.async = true
      script.setAttribute('data-status', State.loading)

      document.body.appendChild(script)

      const setAttributeFromEvent = (event: Event): void => {
        script.setAttribute('data-status', getStateByEventType(event.type))
      }

      script.addEventListener('load', setAttributeFromEvent)
      script.addEventListener('error', setAttributeFromEvent)
    } else {
      setStatus(script.getAttribute('data-status') || State.error)
    }

    const setStateFromEvent = (event: Event): void => {
      const state = getStateByEventType(event.type)

      setStatus(state)
    }

    script.addEventListener('load', setStateFromEvent)
    script.addEventListener('error', setStateFromEvent)

    return (): void => {
      script.removeEventListener('load', setStateFromEvent)
      script.removeEventListener('error', setStateFromEvent)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currency])

  return { isReady: status === State.ready }
}

export { useScript }
