import Link from '@atoms/UILink/Link'
import { Box } from '@lib/UIComponents'
import { LinkType } from '@molecules/SiteLinks/SiteLinks'

const sx = {
  list: {
    maxWidth: '50%'
  },
  listItem: {
    color: 'grays.400',
    marginBottom: 1
  },
  link: {
    transition: 'color 250ms',
    '&:hover': {
      color: 'white',
      textDecoration: 'none'
    }
  }
}

type Props = {
  links: LinkType[]
}

const CostumerCareMenu = ({ links }: Props) => (
  <Box as="ul" sx={sx.list}>
    {links.map((link, index) => {
      const handleOnClick = link.onClick
      return (
        <Box as="li" key={index} sx={sx.listItem}>
          <Link
            to={link.url as string}
            external
            target={link.target}
            onClick={handleOnClick}
            sx={sx.link}
          >
            {link.text}
          </Link>
        </Box>
      )
    })}
  </Box>
)

export default CostumerCareMenu
