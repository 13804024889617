import Container from '@atoms/UIContainer/Container'
import Link from '@atoms/UILink/Link'

import { useFeatureFlag } from '@concepts/FeatureFlag/store/context'

import StackCommerceLogo from '@images/stackcommerce-logo.svg'
import { Box, Image, Text } from '@lib/UIComponents'

const BBBLink =
  'https://www.bbb.org/losangelessiliconvalley/business-reviews/online-retailer/stackcommerce-in-venice-ca-1013267#bbbseal'

const sx = {
  box: {
    borderTop: '1px solid',
    borderColor: 'grays.910'
  },
  container: {
    maxWidth: [null, '650px', '820px', '1280px'],
    display: [null, 'flex'],
    alignItems: 'center',
    height: [null, '68px'],
    p: 4
  },
  cc: {
    lineHeight: 1.7,
    textAlign: ['left', 'center'],
    mx: [0, 'auto'],
    mt: [1, 0],
    color: 'grays.400'
  },
  bbbLink: {
    width: '69px',
    height: '26px',
    float: [null, 'left'],
    display: 'block',
    overflow: 'hidden'
  },
  bbbLImage: {
    maxWidth: 'none',
    height: '100%'
  },
  logoImage: {
    height: 3,
    display: ['block', 'inline']
  }
}

const Copyright = () => {
  const FeatureFlag = useFeatureFlag()

  return (
    <Box sx={sx.box}>
      <Container sx={sx.container}>
        {FeatureFlag.isDisabled('hide_bbb_logo', 'publisher') && (
          <Link
            to={BBBLink}
            target="_blank"
            external
            sx={sx.bbbLink}
            data-testid="hide-bbb-logo"
          >
            <Image
              src="https://seal-sanjose.bbb.org/logo/sehzbus/stackcommerce-1013267.png"
              alt="BBB Logo"
              sx={sx.bbbLImage}
            />
          </Link>
        )}

        <Box sx={sx.cc}>
          <Text sx={{ fontSize: 'sm' }}>
            Made in Venice, CA &amp; powered by{' '}
          </Text>
          <Link
            to="https://www.stacksocial.com"
            target="_blank"
            title="StackCommerce"
            external
          >
            <Image
              src={StackCommerceLogo}
              alt="StackCommerce logo"
              sx={sx.logoImage}
            />
          </Link>
        </Box>
      </Container>
    </Box>
  )
}

export default Copyright
