import { UIStyles } from '@lib/UIComponents/types'
import SpinnerImg from './spinner.gif'
import { Image } from '@lib/UIComponents'

export type SpinnerProps = {
  sx?: UIStyles
  alt: string
  title?: string
}

const Spinner = ({ sx = { height: 4, width: 4 }, ...props }: SpinnerProps) => (
  <Image src={SpinnerImg} sx={sx} {...props} />
)

export default Spinner
