import { curry, isEmpty } from 'ramda'
import { getDecimalValue } from '@utils/numbers'

import { Actions } from '@concepts/Paypal/types/actions'
import { Payment } from '@concepts/Paypal/types/payment'
import { Summary as PaypalSummary } from '@concepts/Paypal/types/summary'
import { Summary } from '@concepts/Cart/types/summary'
import { Address } from 'src/generated/graphql'

import OrderRepository from '@concepts/Paypal/repository/OrderRepository'

const getApplicationAddress = ({ shipping_address }: Payment): Address =>
  ({
    address1: '',
    address2: '',
    city: shipping_address.city,
    countryCode: shipping_address.country_code,
    state: shipping_address.state,
    zip: shipping_address.postal_code
  }) as Address

/*
 * PayPal does not accept negative line items and for that reason we cannot add credits to
 * the purchase items as we used to do in the past.
 *
 * The approach we decided to follow is basically accumulate coupon discount and credits
 */
const getBreakdownItems = (summary: Summary): PaypalSummary => ({
  item_total: {
    currency_code: summary.currency,
    value: getDecimalValue(summary.priceInCents)
  },
  discount: {
    currency_code: summary.currency,
    value: getDecimalValue(
      summary.discountInCents +
        summary.shippingDiscountInCents +
        summary.creditsInCents
    )
  },
  tax_total: {
    currency_code: summary.currency,
    value: getDecimalValue(summary.taxesInCents)
  },
  shipping: {
    currency_code: summary.currency,
    value: getDecimalValue(summary.shippingPriceInCents)
  },
  handling: {
    currency_code: summary.currency,
    value: getDecimalValue(summary.processingFeeInCents)
  }
})

const execute = async (
  onValidateShipping: Function,
  payment: Payment,
  actions: Actions
): Promise<void> => {
  const address = getApplicationAddress(payment)

  const { summary, validations } = await onValidateShipping(address)

  if (!isEmpty(validations)) return actions.reject()

  OrderRepository.patch(
    'amount',
    {
      currency_code: 'USD',
      value: getDecimalValue(summary.totalInCents),
      breakdown: getBreakdownItems(summary)
    },
    actions
  )
}

export default { execute: curry(execute), getBreakdownItems }
