import { pathOr } from 'ramda'
import gql from 'graphql-tag'
import { TApolloClient } from 'src/types/apollo'
import CartItemDTO from '@concepts/Cart/dto/CartItemDTO'
import { CartActionResult } from '@concepts/Cart/types/actions'
import { RemoveCartItemPayload } from 'src/generated/graphql'

export type RemoveUpsellInput = {
  encryptedCartId?: string
  cartItemId: number
}

export const REMOVE_ITEM_MUTATION = gql`
  mutation RemoveCartItem($input: RemoveCartItemInput!) {
    removeCartItem(input: $input) {
      cartItem {
        databaseId
        title
        quantity
        unitPriceCents
        sale {
          databaseId
        }
      }
      success
    }
  }
`

export const removeFromCartRepository = async (
  parameters: RemoveUpsellInput,
  apolloClient: TApolloClient
): Promise<CartActionResult> =>
  apolloClient
    .mutate({
      mutation: REMOVE_ITEM_MUTATION,
      variables: { input: parameters }
    })
    .then(pathOr({} as RemoveCartItemPayload, ['data', 'removeCartItem']))
    .then(
      (data) =>
        ({
          ...data,
          cartItem: data.cartItem ? CartItemDTO.parse(data.cartItem) : null
        }) as CartActionResult
    )
