export enum ShippingPreference {
  GET_FROM_FILE = 'GET_FROM_FILE',
  NO_SHIPPING = 'NO_SHIPPING'
}

export type Address = {
  address_line_1: string
  address_line_2: string | null
  admin_area_1: string
  admin_area_2: string
  country_code: string
  postal_code: string
}
