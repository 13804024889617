import { reject } from 'ramda'

import { getDecimalValue } from '@utils/numbers'
import { formatCents } from '@utils/strings'

import { Summary } from '@concepts/Cart/types/summary'
import { Amount } from '@concepts/Paypal/types/amount'
import { Item as CartItem } from '@concepts/Cart/types/item'
import { PurchaseUnit } from '@concepts/Paypal/types/purchaseUnit'
import { Order } from '@concepts/Paypal/types/order'
import { ShippingPreference } from '../types/address'

type Input = {
  softDescriptor?: string
  getAttributes: Function
}

const getAmount = (
  currency: string,
  summary: Summary,
  breakdown: unknown
): Amount => ({
  currency_code: currency,
  value: getDecimalValue(summary.totalInCents),
  breakdown
})

const toPurchaseUnit = (currency: string, item: CartItem): PurchaseUnit => ({
  name: item.title,
  quantity: item.quantity,
  unit_amount: {
    currency_code: currency,
    value: getDecimalValue(item.unitPriceCents)
  }
})

const getItemsWithoutErrors = reject((item: CartItem) =>
  Boolean(item.errors?.length)
)

const getDescription = (summary: Summary): string => {
  const description = []

  const discount = formatCents(
    summary.discountInCents || summary.shippingDiscountInCents
  )
  const credits = formatCents(summary.creditsInCents)

  if (summary.discountInCents > 0 || summary.shippingDiscountInCents > 0) {
    description.push(
      `You have added a Coupon and earned a discount of ${discount}.`
    )
  }

  if (summary.creditsInCents > 0) {
    description.push(
      `You have applied Credits, we deducted ${credits} in Order total.`
    )
  }

  return description.join(' ')
}

const execute = ({ softDescriptor, getAttributes }: Input): Order => {
  const {
    cart,
    breakdown,
    shippingPreference = ShippingPreference.GET_FROM_FILE
  } = getAttributes()
  const { summary } = cart

  const items = getItemsWithoutErrors(cart.items) as Array<CartItem>
  const currency = summary.currency.toUpperCase()

  return {
    purchase_units: [
      {
        amount: getAmount(currency, summary, breakdown),
        description: getDescription(summary),
        items: items.map((item) => toPurchaseUnit(currency, item)),
        soft_descriptor: softDescriptor
      }
    ],
    application_context: {
      shipping_preference: shippingPreference
    }
  }
}

export default { execute }
