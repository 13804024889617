import { pathOr } from 'ramda'
import { TApolloClient } from 'src/types/apollo'
import { Cart } from '../types/cart'
import { Summary } from '../types/summary'
import { Error } from 'src/types/error'
import { Address } from 'src/generated/graphql'

import { isPresent } from '@utils/logic'

import PrepareCartForCheckoutRepository from '@concepts/Cart/repository/PrepareCartForCheckoutRepository'

const DEFAULT_GATEWAY_REQUEST = 'STRIPE'
const UNKNOWN_API_ERROR =
  'There was an error communicating with the server. Please try again later.'

const buildError = (error: object): string =>
  pathOr(UNKNOWN_API_ERROR, ['message'], error)

type Params = {
  gateway?: string
  attributes?: {
    cart?: Cart
    coupon?: string
    applyCredits?: boolean
    item?: Record<string, number | string>
    cartForSinglePayment?: boolean
    forceCartClearing?: boolean
  }
  shippingAddress?: Address
  billingAddress?: Address
  encryptedCartId?: string
}

export type PrepareCartForCheckoutResult = {
  cart: Cart
  summary: Summary
  validations: Array<Error>
  expiredSales: Array<string>
  errors?: Array<Error>
  encryptedCartId?: string
}

const apply = async (
  {
    gateway,
    attributes,
    shippingAddress,
    billingAddress,
    encryptedCartId
  }: Params,
  client: TApolloClient
): Promise<PrepareCartForCheckoutResult> => {
  try {
    const code = pathOr('', ['coupon'], attributes)
    const cartItemId = pathOr(undefined, ['item', 'id'], attributes)
    const quantity = pathOr(undefined, ['item', 'quantity'], attributes)
    const saleId = pathOr(undefined, ['item', 'saleId'], attributes)
    const childSaleId = pathOr(undefined, ['item', 'childSaleId'], attributes)
    const bidPrice = pathOr(undefined, ['item', 'bidPrice'], attributes)
    const source = pathOr(undefined, ['item', 'source'], attributes)

    const result = await PrepareCartForCheckoutRepository.execute(
      {
        gateway: gateway || DEFAULT_GATEWAY_REQUEST,
        attributes: {
          cartItemId,
          quantity,
          saleId,
          bidPrice,
          childSaleId,
          source,
          coupon: code,
          applyCredits: Boolean(attributes?.applyCredits),
          cartForSinglePayment: attributes?.cartForSinglePayment,
          forceCartClearing: attributes?.forceCartClearing
        },
        shippingAddress: isPresent(shippingAddress)
          ? shippingAddress
          : undefined,
        billingAddress: isPresent(billingAddress) ? billingAddress : undefined,
        encryptedCartId
      },
      client
    )

    if (isPresent(result.errors)) throw pathOr({}, ['0'], result.errors)

    return {
      cart: result.cart,
      expiredSales: result.expiredSales,
      summary: result.summary,
      validations: result.validations,
      encryptedCartId: result.encryptedCartId
    }
  } catch (error) {
    return Promise.reject(buildError(error as object))
  }
}

export default { apply }
