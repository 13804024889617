import { isNil } from 'ramda'
import { getDecimalValue } from '@utils/numbers'
import { Order, Maybe } from 'src/generated/graphql'

import analytics from './analytics'

import TrackPurchaseEvent, {
  PurchaseEventType,
  PurchaseEventSaleType
} from '@concepts/Marketing/app/TrackPurchaseEvent'

const PURCHASE_ACTIONS = ['PURCHASE', 'REDEEM_FREEBIE']

type SCDataLayerType = {
  initiateCheckout: Function
  trackPurchase: Function
}

export type EventAttrs = {
  orderId?: Maybe<number>
  productPrice?: Maybe<number>
  productIds?: Array<number>
  saleArray?: Array<PurchaseEventSaleType>
}

const track = (action: string, attrs: EventAttrs = {}): void => {
  analytics.trackSCDataLayer(action, attrs)
}

const SCDataLayer: SCDataLayerType = {
  initiateCheckout: (
    cartTotalInCents: number,
    saleIds: Array<number>
  ): void => {
    track('INITIATE_CHECKOUT', {
      productPrice: getDecimalValue(cartTotalInCents),
      productIds: saleIds
    })
  },
  trackPurchase: (order: Order): void => {
    const data = TrackPurchaseEvent.process(order) as PurchaseEventType

    if (isNil(data) || !PURCHASE_ACTIONS.includes(data.event)) return

    track(data.event, { ...data.metadata })
  }
}

export default SCDataLayer
