import { getDecimalValue } from '@utils/numbers'

import { ShippingPreference } from '@concepts/Paypal/types/address'
import { Order } from '@concepts/Paypal/types/order'

type Input = {
  softDescriptor?: string
  getAttributes: Function
}

const execute = ({ softDescriptor, getAttributes }: Input): Order => {
  const { sale, discount, taxes, shippingCost, currency } = getAttributes()

  return {
    purchase_units: [
      {
        amount: {
          currency_code: currency,
          value: getDecimalValue(
            sale.priceWithDiscountInCents - discount + shippingCost + taxes
          ),
          breakdown: {
            ...{
              item_total: {
                currency_code: currency,
                value: getDecimalValue(sale.priceWithDiscountInCents)
              },
              discount: {
                currency_code: currency,
                value: getDecimalValue(discount)
              },
              tax_total: {
                currency_code: currency,
                value: getDecimalValue(taxes)
              }
            },
            ...(shippingCost > 0
              ? {
                  shipping: {
                    currency_code: currency,
                    value: getDecimalValue(shippingCost)
                  }
                }
              : {})
          }
        },
        items: [
          {
            name: sale.name || sale.title,
            quantity: 1,
            unit_amount: {
              currency_code: currency,
              value: getDecimalValue(sale.priceWithDiscountInCents)
            }
          }
        ],
        soft_descriptor: softDescriptor
      }
    ],
    application_context: {
      shipping_preference: ShippingPreference.NO_SHIPPING
    }
  }
}

export default { execute }
