import gql from 'graphql-tag'
import { pathOr } from 'ramda'
import { TApolloClient } from 'src/types/apollo'
import { Cart } from '../types/cart'
import { Cart as CartFields } from 'src/generated/graphql'
import { ApolloQueryResult } from 'apollo-client'

import CartDTO from '../dto/CartDTO'

const CART_QUERY = gql`
  query Cart($encryptedCartId: String) {
    cart(encryptedCartId: $encryptedCartId) {
      databaseId
      intShippingCostCents
      hasCannabidiolSale
      hasRecurringSale
      shippingCostCents
      acceptCredits
      shippable
      processingFeeCents
      pricesChanged

      upsell {
        databaseId
        name
        pictureAttributes
        priceInCents
      }

      summary {
        priceInCents
        retailPriceInCents
        creditsInCents
        discountInCents
        shippingPriceInCents
        shippingDiscountInCents
        processingFeeInCents
        taxesInCents
        totalInCents
        currency
      }

      items {
        databaseId
        quantity
        unitPriceCents
        title
        payWithCreditsAvailable
        source

        sale {
          category {
            name
          }

          saleGroupOption {
            databaseId
            saleGroupId
            name
          }

          name
          type {
            name
          }
          databaseId
          priceInCents
          retailPriceInCents
          maxPerUser
          maxAvailable
          pictureAttributes
          excludeFromCoupons
          excludeFromCredits
          ageRestriction
          slug

          recurring
          recurringInterval
          isRecurringTrial
          recurringTrialPeriodDays
        }
      }
    }
  }
`

type QueryParams = {
  encryptedCartId?: string
}

const getCartFields = (payload: ApolloQueryResult<CartFields>): Cart => {
  const fields = pathOr({} as CartFields, ['data', 'cart'], payload)

  return CartDTO.parse(fields)
}

const find = async (
  { encryptedCartId }: QueryParams,
  apolloClient: TApolloClient
): Promise<Cart> => {
  const result = await apolloClient.query({
    query: CART_QUERY,
    fetchPolicy: 'network-only',
    variables: { encryptedCartId }
  })

  return getCartFields(result)
}

export default { find }
