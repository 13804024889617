import { Item } from '../types/item'
import { Cart } from '../types/cart'

type useCartFormatterType = {
  addItemToCart: (cart: Cart, cartItem: Item) => Cart
  removeItemFromCart: (cart: Cart, cartItem: Item) => Cart
}

const useCartFormatter = (): useCartFormatterType => {
  const addItemToCart = (cart: Cart, cartItem: Item): Cart => ({
    ...cart,
    items: [...cart.items.filter((item) => item.id !== cartItem.id), cartItem]
  })

  const removeItemFromCart = (cart: Cart, cartItem: Item): Cart => ({
    ...cart,
    items: cart.items.filter((item) => item.id !== cartItem.id),
    summary: {
      ...cart.summary,
      totalInCents: cart.items.reduce((totalInCents, item) => {
        return totalInCents - item.unitPriceCents
      }, cart.summary.totalInCents)
    }
  })

  return {
    addItemToCart,
    removeItemFromCart
  }
}

export default useCartFormatter
