import { not } from 'ramda'

import Container from '@atoms/UIContainer/Container'
import Currency from '@atoms/UICurrency/Currency'
import FullLoader from '@atoms/Loader/FullLoader'
import Overlay from '@atoms/Overlay/Overlay'
import Separator from '@atoms/Separator/Separator'
import Button from '@atoms/UIButton/Button'
import Link from '@atoms/UILink/Link'

import useFetchUpsellCartRecommendations from '@concepts/Cart/hooks/useFetchUpsellCartRecommendations'
import useMiniCart from '@concepts/Cart/hooks/useMiniCart'
import { useCartPopupContext } from '@concepts/Cart/store/popupContext'
import PaypalPopupButton from '@concepts/Paypal/views/PaypalPopupButton/PaypalPopupButton'

import cartAnalytics from '@lib/gtm/events/cart'
import { Box, Flex, Text } from '@lib/UIComponents'

import { fadeIn } from 'src/components/theme/animations'
import Item from '../Item/Item'
import AddedToCartMessage from './AddedToCartMessage'
import CartEmpty from './CartEmpty'
import Header from './Header'
import Upsell from './Upsell'

/* istanbul ignore next */
const styles = {
  container: (navBarHeight: number, popupMaxHeight: string): object => ({
    position: 'fixed',
    left: ['0', '0', '50%', '55%'],
    transform: [
      null,
      null,
      'translateX(-15%)',
      'translateX(10%)',
      'translateX(23%)',
      'translateX(40%)',
      'translateX(55%)'
    ],
    top: [
      navBarHeight ? `${navBarHeight}px` : '100px',
      navBarHeight ? `${navBarHeight}px` : '100px',
      navBarHeight ? `${navBarHeight - 65}px` : '105px',
      navBarHeight ? `${navBarHeight - 80}px` : '115px'
    ],
    zIndex: '3',
    width: ['100%', '100%', '468px'],
    maxWidth: '100%',
    background: 'white',
    boxShadow: '0px 19px 25px rgba(0, 0, 0, 0.15)',
    borderRadius: ['0 0 4px 4px', '0 0 4px 4px', '4px'],
    maxHeight: [popupMaxHeight, 'auto'],
    animation: `${fadeIn} 150ms ease-in-out`,
    overflowX: 'auto'
  }),
  itemsContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: ['auto', '350px'],
    overflowY: 'auto',
    gap: 4
  },
  overflow: { zIndex: 1 },
  subTotalText: {
    ml: 8,
    fontSize: 2
  },
  subTotalPrice: {
    mr: 8,
    fontSize: [2, '18px'],
    fontWeight: 'bold'
  },
  content: {
    py: 4,
    px: [4, 4],
    mb: [2, 3]
  },
  separator: {
    mt: '8px',
    mb: '8px',
    borderBottom: '1px dashed',
    borderBottomColor: 'grays.100'
  },
  expressCheckout: {
    fontSize: 2,
    textAlign: 'center',
    color: 'grays.910',
    mt: ['0', '0'],
    pt: ['0', '0'],
    mb: '4'
  },
  total: {
    order: [2, 2, 0],
    justifyContent: 'space-between',
    mb: [0, 0, 4],
    pt: 4
  }
}

const MiniCart = () => {
  const { isOpen, setIsOpen, displayItemAddedMessage, handleMiniCartPopup } =
    useCartPopupContext()
  const {
    quantity,
    cart,
    navbarHeight,
    popUpMobileMaxHeight,
    isSubmitting,
    hidePaypal,
    itemsText,
    hasQuantity,
    isDisabled,
    addSaleToCart,
    handleUpdateQuantity,
    removeSaleFromCart
  } = useMiniCart()

  const { recommendations } = useFetchUpsellCartRecommendations({
    isOpen,
    cart,
    quantity
  })

  const applyAnalytics = () => {
    cartAnalytics.trackCheckoutButtonMiniCart()
  }

  return (
    <Box
      onMouseEnter={() => setIsOpen(true)}
      sx={{ display: isOpen ? 'block' : 'none' }}
    >
      <Overlay
        freezeScroll={isOpen}
        onClick={() => handleMiniCartPopup({ isAddItem: false })}
        sx={styles.overflow}
        data-testid="cartpopup-overlay"
      />
      <Box
        sx={styles.container(parseInt(navbarHeight), popUpMobileMaxHeight)}
        onMouseLeave={() => setIsOpen(false)}
        data-testid="cartpopup-outer-div"
      >
        <Box sx={styles.content}>
          <Header
            handleClosePopup={() => handleMiniCartPopup({ isAddItem: false })}
          />
          {not(hasQuantity) && <CartEmpty />}
          {hasQuantity && (
            <Flex sx={{ flexDirection: 'column' }}>
              {displayItemAddedMessage && <AddedToCartMessage />}
              <Box sx={{ order: [2, 2, 0] }}>
                <Container sx={styles.itemsContainer}>
                  {cart.items.map((item) => {
                    return (
                      <Item
                        handleUpdateQuantity={handleUpdateQuantity}
                        removeSaleFromCart={removeSaleFromCart}
                        key={item.id}
                        item={item}
                      />
                    )
                  })}
                </Container>

                {not(hidePaypal) && (
                  <>
                    <Separator text="OR" sx={styles.separator} />
                    <Flex
                      sx={{
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <Text as="h3" sx={styles.expressCheckout}>
                        Express Checkout
                      </Text>
                      <PaypalPopupButton
                        disabled={isDisabled}
                        sx={{ width: '200px' }}
                      />
                    </Flex>
                  </>
                )}
              </Box>
              <Flex sx={styles.total}>
                <Text sx={styles.subTotalText}>Subtotal:</Text>
                <Box>
                  <Currency sx={styles.subTotalPrice}>
                    {cart.summary?.priceInCents}
                  </Currency>
                </Box>
              </Flex>

              <Button
                as={Link}
                to="/checkout"
                onClick={applyAnalytics}
                sx={{
                  order: [1, 1, 0],
                  justifyContent: 'center',
                  alignSelf: 'center',
                  width: '100%',
                  maxWidth: '500px',
                  mb: [7, 7, 0]
                }}
                variant="dark"
                size="large"
              >
                Checkout ({itemsText})
              </Button>
            </Flex>
          )}
        </Box>
        <Upsell
          upsells={quantity ? recommendations : []}
          addToCart={addSaleToCart}
        />
      </Box>
      <FullLoader show={Boolean(isSubmitting)} message="Processing order..." />
    </Box>
  )
}

export default MiniCart
