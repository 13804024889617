import Container from '@atoms/UIContainer/Container'
import Newsletter, { NewsletterType } from '@molecules/Newsletter/Newsletter'

import Copyright from './Copyright'
import AboutUs from './AboutUs'
import CostumerCare from './CustomerCare'
import { usePublisherContext } from '@concepts/Publisher/store/context'
import { mediaSizesValues } from '@lib/UIComponents/Theme/breakpoints'
import { UIStyles } from '@lib/UIComponents/types'
import { Box } from '@lib/UIComponents'

const newsletter: NewsletterType = {
  headline: 'Get on the List!',
  subHeadline:
    'Enjoy early access, exclusive offers, and 15% off your first order.',
  analyticsName: 'footer',
  btnText: 'Sign Up',
  variant: 'dark'
}

const style = {
  container: (proprietary: boolean) => ({
    px: 4,
    pt: 8,
    pb: [4, 8],
    maxWidth: [null, ...mediaSizesValues],
    display: [null, 'flex'],
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    '> div': {
      width: proprietary ? null : [null, null, null, '50%']
    }
  })
}

type Props = {
  sx?: UIStyles
}

const Footer = ({ sx }: Props) => {
  const { proprietary } = usePublisherContext()

  return (
    <Box
      as="footer"
      sx={{
        bg: 'footer',
        color: 'white',
        ...sx
      }}
    >
      <Container sx={style.container(proprietary as boolean)}>
        <Newsletter
          {...newsletter}
          formOrientation={
            proprietary ? 'column' : ['column', 'column', 'column', 'row']
          }
        />
        <CostumerCare />
        {proprietary && <AboutUs />}
      </Container>

      <Copyright />
    </Box>
  )
}

export default Footer
