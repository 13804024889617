import { lt } from 'ramda'

import useGuestToken from '@concepts/Auth/hooks/useGuestToken'
import { useAuthContext } from '@concepts/Auth/store/context'
import { usePublisherContext } from '@concepts/Publisher/store/context'

import { LinkType } from '@molecules/SiteLinks/SiteLinks'

import { defaultMediaSizes } from '@theme/breakpoints'
import costumerCareLinks from '@utils/costumerCareLinks'
import { useWindowDimensionsContext } from '@providers/windowDimensions/windowDimensionContext'

type useCustomerCareReturnType = {
  linksColumns: LinkType[][]
}

const useCustomerCare = (): useCustomerCareReturnType => {
  const [{ isSignedIn }] = useAuthContext()
  const { gtoken } = useGuestToken()
  const { proprietary } = usePublisherContext()
  const {
    windowDimensions: { width }
  } = useWindowDimensionsContext()

  const columnsQuantity =
    proprietary || lt(width as number, defaultMediaSizes.ScreenL)
      ? 'two'
      : 'three'

  const linksColumns: LinkType[][] = costumerCareLinks(
    columnsQuantity,
    isSignedIn,
    gtoken
  )

  return {
    linksColumns
  }
}

export default useCustomerCare
