import { getCookie } from '@utils/cookies'
import { AFFILIATE_COOKIE_NAME } from '@config/env'
import { CartState } from '@concepts/Cart/types/cart'
import { pathOr } from 'ramda'

type Response = {
  affiliateCode?: string
  coupon?: string
  applyCredits?: boolean
}

const checkoutFilling = ({
  coupons,
  credit
}: Pick<CartState, 'coupons' | 'credit'>): Response => ({
  affiliateCode: getCookie(AFFILIATE_COOKIE_NAME),
  coupon: pathOr('', ['codes', '0', 'code'], coupons),
  applyCredits: credit.isApplied
})

export default checkoutFilling
