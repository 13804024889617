import { Box, Text } from '@lib/UIComponents'
import CostumerCareMenu from './FooterMenu/CostumerCareMenu'
import useCustomerCare from './hooks/useCustomerCare'

const sx = {
  container: {
    mb: [8, 8, 8, 0]
  },
  title: {
    fontSize: [4, '2xl'],
    fontWeight: 'bold',
    mb: 4
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between'
  }
}

const CostumerCare = () => {
  const { linksColumns } = useCustomerCare()

  return (
    <Box sx={sx.container}>
      <Text as="p" sx={sx.title}>
        Customer Care
      </Text>

      <Box sx={sx.list}>
        {linksColumns.map((links, index) => (
          <CostumerCareMenu key={index} links={links} />
        ))}
      </Box>
    </Box>
  )
}

export default CostumerCare
