const SET_PURCHASE = 'SET_PURCHASE'
const UPDATE_PURCHASE = 'UPDATE_PURCHASE'
const NEXT_DEFAULT = 'NEXT_DEFAULT'
const SET_PURCHASE_ADDRESS = 'SET_PURCHASE_ADDRESS'
const SET_PURCHASE_BILLING_ADDRESS = 'SET_PURCHASE_BILLING_ADDRESS'

export {
  SET_PURCHASE,
  UPDATE_PURCHASE,
  NEXT_DEFAULT,
  SET_PURCHASE_ADDRESS,
  SET_PURCHASE_BILLING_ADDRESS
}
