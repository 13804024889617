import gql from 'graphql-tag'
import { TApolloClient } from 'src/types/apollo'
import { pathOr, pick } from 'ramda'
import { Cart } from '../types/cart'
import {
  Cart as CartFields,
  Address as AddressFields
} from 'src/generated/graphql'

import { Summary } from '../types/summary'
import { Error } from 'src/types/error'

import CartDTO from '../dto/CartDTO'
import CartItemDTO from '../dto/CartItemDTO'
import { PrepareCartForCheckoutResult } from '../app/PrepareCartForCheckout'

const PREPARE_CART_FOR_CHECKOUT_MUTATION = gql`
  mutation PrepareCartForCheckout($input: PrepareCartForCheckoutInput!) {
    prepareCartForCheckout(input: $input) {
      expiredSales

      cart {
        databaseId
        shippable
        acceptCredits
        hasRecurringSale
        intShippingCostCents
        shippingCostCents
        processingFeeCents

        upsell {
          databaseId
          name
          pictureAttributes
          priceInCents
        }

        items {
          databaseId
          quantity
          unitPriceCents
          title
          payWithCreditsAvailable
          baseCartItemId
          source

          sale {
            category {
              name
            }

            saleGroupOption {
              databaseId
              saleGroupId
              name
            }

            name
            databaseId
            priceInCents
            retailPriceInCents
            maxPerUser
            maxAvailable
            pictureAttributes
            excludeFromCoupons
            excludeFromCredits
            ageRestriction
            slug

            recurring
            recurringInterval
            isRecurringTrial
            recurringTrialPeriodDays
          }
        }
      }

      encryptedCartId

      summary {
        priceInCents
        retailPriceInCents
        creditsInCents
        discountInCents
        shippingPriceInCents
        shippingDiscountInCents
        processingFeeInCents
        taxesInCents
        totalInCents
        currency
      }

      validations {
        message
        path
      }

      errors {
        message
        path
      }
    }
  }
`

type Input = {
  gateway: string
  attributes: {
    cartId?: number
    cartItemId?: number
    bidPrice?: number
    quantity?: number
    childSaleId?: number
    coupon?: string
    applyCredits?: boolean
    saleId?: number
    cartForSinglePayment?: boolean
    forceCartClearing?: boolean
    source?: string
  }
  shippingAddress?: AddressFields
  billingAddress?: AddressFields
  encryptedCartId?: string
}

const getCartFields = (payload: PrepareCartForCheckoutResult): Cart => {
  const fields = pathOr({} as CartFields, ['cart'], payload)

  return CartDTO.parse(fields, CartItemDTO.parseWithErrors(payload.validations))
}

const toShippingAddress = (
  address?: AddressFields
): Partial<AddressFields> | undefined => {
  if (!address) return

  return pick(
    ['address1', 'address2', 'city', 'state', 'countryCode', 'zip'],
    address
  )
}

const toBillingAddress = (
  address?: AddressFields
): Partial<AddressFields> | undefined => {
  if (!address || !address.countryCode) return

  return pick(['countryCode', 'state', 'zip'], address)
}

const execute = async (
  parameters: Input,
  apolloClient: TApolloClient
): Promise<PrepareCartForCheckoutResult> =>
  await apolloClient
    .mutate({
      mutation: PREPARE_CART_FOR_CHECKOUT_MUTATION,
      variables: {
        input: {
          gateway: parameters.gateway,
          attributes: parameters.attributes,
          shippingAddress: toShippingAddress(parameters.shippingAddress),
          billingAddress: toBillingAddress(parameters.billingAddress),
          encryptedCartId: parameters.encryptedCartId
        }
      }
    })
    .then(
      pathOr({} as PrepareCartForCheckoutResult, [
        'data',
        'prepareCartForCheckout'
      ])
    )
    .then((payload) => ({
      cart: getCartFields(payload),
      expiredSales: payload.expiredSales,
      summary: payload.summary as Summary,
      errors: payload.errors as Array<Error>,
      validations: payload.validations as Array<Error>,
      encryptedCartId: payload.encryptedCartId
    }))

export default { execute }
