import { captureException } from '@sentry/react'
import { pipe, join, split, pathOr, last } from 'ramda'
import {
  SOMETHING_WENT_WRONG,
  EMAIL_ALREADY_TAKEN,
  fraudulentOrder
} from '@utils/defaultMessages'
import { capitalize } from '@utils/strings'

import type { MutationError } from 'src/generated/graphql'

export type GraphQLError = MutationError & {
  graphQLErrors: Record<string, object>[]
}
export type ErrorType = MutationError | GraphQLError | string | Error
export type ErrorList = Array<ErrorType>

export const captureError = (
  error: ErrorType,
  params: Record<string, object>
): void => {
  captureException(error, { extra: params })
}

const pathToString = (error: MutationError): string =>
  pipe(
    join(' '),
    split('_'),
    join(' '),
    capitalize
  )(pathOr([], ['path'], error))

const pathMessageFormatter = (error: MutationError, message: string): string =>
  error.path ? `${pathToString(error)} ${message}` : message

const MAPPED_ERRORS = {
  fraudulentOrder: /error code: 900/i,
  emailAlreadyTaken: /email.*taken/i,
  validatorRegex: /^(must be filled|is required|not found|is invalid)/,
  graphql: /graphql/i,
  network: /network/i
}

const capitalizeFirstLetter = (message: string) =>
  message.charAt(0).toUpperCase() + message.slice(1)

const messageFormatter = (error: ErrorType, errorParams: object): string => {
  const message = typeof error === 'string' ? error : error.message

  if (message.match(MAPPED_ERRORS.fraudulentOrder)) return fraudulentOrder()

  if (message.match(MAPPED_ERRORS.emailAlreadyTaken)) return EMAIL_ALREADY_TAKEN

  if (message.match(MAPPED_ERRORS.validatorRegex))
    return pathMessageFormatter(error as MutationError, message)

  if (message.match(MAPPED_ERRORS.graphql)) {
    const gqlError = last((error as GraphQLError).graphQLErrors || [])

    if (gqlError) {
      const formattedError = {
        input: JSON.stringify(gqlError.value),
        problems: JSON.stringify(gqlError.problems)
      }

      captureError(error, { graphQLError: formattedError })
    }

    return SOMETHING_WENT_WRONG
  }

  if (message.match(MAPPED_ERRORS.network)) {
    captureError(error, { ...errorParams })

    return SOMETHING_WENT_WRONG
  }

  return message
}

const errorFormatter = (
  errors: ErrorList = [SOMETHING_WENT_WRONG],
  params: object = {}
): string => {
  return errors
    .map((e) => capitalizeFirstLetter(messageFormatter(e, params)))
    .join(' ')
}

export default errorFormatter
