import { removeCookie } from '@utils/cookies'
import { Cart } from '../types/cart'
import { handleRedirectToIndexPage } from './handleRedirectToIndexPage'
import { COUPON_CODE_COOKIE_NAME } from '@config/env'

const postCartUpdate = (cart: Cart) => {
  if (cart.items?.length === 0) {
    removeCookie(COUPON_CODE_COOKIE_NAME)
    handleRedirectToIndexPage(cart)
  }
}

export default postCartUpdate
