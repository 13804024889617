import { map, isEmpty } from 'ramda'
import { Cart } from '@concepts/Cart/types/cart'
import { Cart as CartFields, CartItem } from 'src/generated/graphql'

import CartItemDTO from './CartItemDTO'

const highestAgeRestriction = (cartItems: Array<CartItem>): number =>
  Math.max(
    ...cartItems
      .filter((item) => item.sale.ageRestriction !== 'all_ages')
      .map((item) => parseInt(item.sale.ageRestriction)),
    0
  )

const parse = (cart: CartFields, cartItemParser = CartItemDTO.parse): Cart => {
  if (isEmpty(cart)) return { items: [] } as unknown as Cart

  return {
    id: cart.databaseId,
    hasRecurringSale: Boolean(cart.hasRecurringSale),
    hasCannabidiolSale: Boolean(cart.hasCannabidiolSale),
    pricesChanged: cart.pricesChanged,
    shippable: Boolean(cart.shippable),
    acceptCredits: Boolean(cart.acceptCredits),
    summary: cart.summary || {},
    items: map(cartItemParser, cart.items),
    ageRestriction: highestAgeRestriction(cart.items),
    shippingCostCents: cart.shippingCostCents,
    processingFeeCents: cart.processingFeeCents,
    processingFeeApplicable: cart.processingFeeCents > 0,
    intShippingCostCents: cart.intShippingCostCents,
    upsell: cart.upsell
  } as Cart
}

export default { parse }
