import { Card as UICard } from '@lib/UIComponents'
import { CardProps as UICardProps } from '@lib/UIComponents/Card/Card'

type Size = 'small' | 'medium' | 'large'

export type Props = {
  size?: Size
} & UICardProps

const sizes = {
  small: {
    p: 2
  },
  medium: {
    p: 4
  },
  large: {
    p: 6
  }
}

const Card = ({
  variant = 'outline',
  size = 'medium',
  sx = {},
  ...props
}: Props) => (
  <UICard variant={variant} {...props} sx={{ ...sizes[size as Size], ...sx }} />
)

export default Card
