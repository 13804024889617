import { TApolloClient } from 'src/types/apollo'
import { CartItemSource } from 'src/generated/graphql'
import { addToCartRepository } from '../repository/AddToCart'
import { CartActionResult } from '@concepts/Cart/types/actions'
import { buildError } from '../utils/buildError'
import { Maybe } from 'graphql/jsutils/Maybe'
import { getItemListName } from '@lib/gtm/events/utils'

type AddItemParams = {
  saleId: Maybe<number>
  saleSlug: string
  quantity?: number
  childSaleId?: number | null
  bidPrice?: number | null
  priceToken?: string
  maxPerUser?: number
  source?: CartItemSource
  encryptedCartId?: string
  client: TApolloClient
}

const add = async ({
  saleId,
  saleSlug,
  quantity = 1,
  childSaleId,
  bidPrice,
  priceToken,
  maxPerUser,
  source,
  encryptedCartId,
  client
}: AddItemParams): Promise<CartActionResult> => {
  try {
    const itemListName = getItemListName(saleSlug)

    const cartItem = await addToCartRepository(
      {
        childSaleId,
        saleId: Number(saleId),
        quantity,
        bidPrice: bidPrice || undefined,
        priceToken: priceToken || undefined,
        maxPerUser,
        source,
        itemListName
      },
      client,
      encryptedCartId
    )

    return cartItem
  } catch (error) {
    return Promise.reject(buildError(error as object))
  }
}

export default { add }
