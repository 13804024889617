/* istanbul ignore file */

import { Sale } from 'src/generated/graphql'

export const fetchCartSaleImage = (sale?: Partial<Sale>): string =>
  sale?.pictureAttributes?.cart_image?.img_tag?.src ||
  sale?.imageCollection?.cartImage?.pictureAttributes?.img_tag.src ||
  ''

export const fetchOrderConfirmationSaleImage = (sale: Sale): string =>
  sale?.imageCollection?.showcaseImage.pictureAttributes.img_tag?.src || ''

export const fetchUpsellSaleImage = (sale: Sale): string =>
  sale?.imageCollection?.orderConfirmationUpsell.pictureAttributes.img_tag
    ?.src || ''
