import { Sale } from 'src/generated/graphql'

const parse = (sales: Array<Sale>): Array<Sale> =>
  sales.map((sale) => ({
    ...sale,
    priceInCents: sale.retailPriceInCents,
    priceWithDiscountInCents: sale.priceInCents
  }))

export default { parse }
