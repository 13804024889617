import { Actions } from '@concepts/Paypal/types/actions'

import BuildRegularOrder from '@concepts/Paypal/app/BuildRegularOrder'
import BuildUpsellOrder from '@concepts/Paypal/app/BuildUpsellOrder'

const INTENT = 'authorize'

enum Type {
  REGULAR,
  UPSELL
}

type Input = {
  type: Type
  actions: Actions
  softDescriptor?: string
  getAttributes: Function
}

const execute = ({
  type,
  actions,
  softDescriptor,
  getAttributes
}: Input): Promise<unknown> => {
  const builder = type === Type.REGULAR ? BuildRegularOrder : BuildUpsellOrder

  const { purchase_units, application_context } = builder.execute({
    softDescriptor,
    getAttributes
  })

  return actions.order.create({
    intent: INTENT,
    purchase_units,
    application_context
  })
}

export default { Type, execute }
