import { groupBy, isEmpty, pathOr, pipe, values, flatten } from 'ramda'
import { getDecimalValue } from '@utils/numbers'
import { GroupedOrderLineItem, Order } from 'src/generated/graphql'
import { Maybe } from 'src/types/maybe'

type GroupedOrderLineItems = Array<GroupedOrderLineItem>
type Group = Record<string, GroupedOrderLineItems>

export type PurchaseEventSaleType = {
  id: Maybe<number>
  price: number
  productQuantity: number
}

export type PurchaseEventType = {
  event: string
  metadata: {
    orderId: Maybe<number>
    productPrice: Maybe<number>
    saleArray: Array<PurchaseEventSaleType>
  }
}

const getLineItemsBySaleType = (order: Order): Group => {
  const lineItems = pathOr([], ['groupedLineItems'], order).filter(
    (lineItem: GroupedOrderLineItem) => !!lineItem.sale.databaseId
  )

  return groupBy(
    (l: GroupedOrderLineItem) => l.sale.type.name,
    lineItems
  ) as Group
}

const buildSaleEvent = (
  items: GroupedOrderLineItems
): Array<PurchaseEventSaleType> =>
  items.map((item: GroupedOrderLineItem) => {
    const paidPrice = pathOr(
      0,
      ['lineItems', '0', 'paidPriceInCents'],
      item
    ) as number

    return {
      id: item.sale.databaseId,
      price: getDecimalValue(paidPrice),
      productQuantity: item.quantity
    }
  })

const buildEvent = (
  event: string,
  order: Order,
  groupedLineItems: Group
): Maybe<PurchaseEventType> => {
  const groupedSales = pipe(values, flatten)(groupedLineItems)

  if (isEmpty(groupedSales)) return

  return {
    event,
    metadata: {
      orderId: order.databaseId,
      productPrice: getDecimalValue(Number(order.priceInCents)),
      saleArray: buildSaleEvent(groupedSales)
    }
  }
}

const process = (order: Order): Maybe<PurchaseEventType> => {
  const { Freebie, ...Regular } = getLineItemsBySaleType(order)

  return Freebie
    ? buildEvent('REDEEM_FREEBIE', order, { Freebie } as Group)
    : buildEvent('PURCHASE', order, Regular)
}

export default { process }
