import { useCallback } from 'react'
import { useNotyfContext } from '@atoms/Notyf/context'
import { MutationError } from 'src/generated/graphql'
import { pathOr } from 'ramda'
import { SOMETHING_WENT_WRONG } from '@utils/defaultMessages'
import { Notyf } from 'notyf'

type useCartNotifyType = {
  showError: (errors?: Array<MutationError>) => void
  showExpiredSalesInfo: (message: Array<string>) => void
}

const useCartNotify = (): useCartNotifyType => {
  const notyf = useNotyfContext()

  const showError = useCallback(
    (errors?: Array<MutationError>) => {
      ;(notyf as Notyf).error({
        message: pathOr(SOMETHING_WENT_WRONG, ['0', 'message'])(errors),
        duration: 0
      })
    },
    [notyf]
  )

  const showExpiredSalesInfo = useCallback(
    (expiredSales: Array<string>) => {
      const expiredSalesStr = expiredSales.join(', ')
      const expiredSalesInfo = `The following sale(s) are no longer available and have been removed from your cart: ${expiredSalesStr}.`

      ;(notyf as Notyf).open({
        type: 'info',
        message: expiredSalesInfo,
        duration: 0,
        dismissible: true
      })
    },
    [notyf]
  )

  return { showError, showExpiredSalesInfo }
}

export default useCartNotify
