import CloseIcon from '@icons/close.svg'
import Button from '@atoms/UIButton/Button'
import { Flex, Text } from '@lib/UIComponents'

type Props = {
  handleClosePopup: Function
}

const styles = {
  container: {
    justifyContent: 'space-between',
    mb: 4
  },
  close: {
    svg: {
      fontSize: 3
    },
    p: 0
  },
  text: {
    fontSize: 5,
    fontWeight: 'bold'
  }
}

const Header = ({ handleClosePopup }: Props) => (
  <Flex sx={styles.container}>
    <Text sx={styles.text}>Your Cart</Text>

    <Button
      title="Close"
      size="small"
      variant="close"
      aria-label="Close"
      sx={styles.close}
      onClick={() => handleClosePopup()}
    >
      <CloseIcon />
    </Button>
  </Flex>
)

export default Header
