import { Box, Flex } from 'rebass'
import Text from '@atoms/Text/Text'
import { SuccessCheckoutIcon } from '@atoms/Icon/Icon'

const styles = {
  container: {
    backgroundColor: '#EDF8F4',
    border: '1px solid',
    borderColor: 'greens.700',
    borderRadius: '4px',
    alignItems: 'center',
    height: '40px',
    mb: 4,
    px: 2,
    py: 1
  },
  successIcon: {
    svg: {
      color: 'primary',
      display: 'block',
      width: '25px',
      height: '25px'
    }
  },
  text: {
    color: 'blacks.400',
    ml: 2
  }
}

const AddedToCartMessage = () => (
  <Flex sx={styles.container}>
    <Box sx={styles.successIcon}>
      <SuccessCheckoutIcon />
    </Box>
    <Text datatest-id="addMessage" sx={styles.text}>
      An item was added to your cart!
    </Text>
  </Flex>
)

export default AddedToCartMessage
