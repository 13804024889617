import { Address } from 'src/generated/graphql'
import {
  SET_PURCHASE,
  UPDATE_PURCHASE,
  NEXT_DEFAULT,
  SET_PURCHASE_ADDRESS,
  SET_PURCHASE_BILLING_ADDRESS
} from './actionTypes'

const setPurchase = (data: object): object => ({
  type: SET_PURCHASE,
  data
})

const updatePurchase = (data: object): object => ({
  type: UPDATE_PURCHASE,
  data
})

const setNextDefault = (data: object): object => ({
  type: NEXT_DEFAULT,
  data
})

const setPurchaseAddress = (data: Address): object => ({
  type: SET_PURCHASE_ADDRESS,
  data
})

const setPurchaseBillingAddress = (data: Address): object => ({
  type: SET_PURCHASE_BILLING_ADDRESS,
  data
})

export {
  setPurchase,
  updatePurchase,
  setNextDefault,
  setPurchaseAddress,
  setPurchaseBillingAddress
}
