import analytics from './analytics'
import { setItemListNamesCookie } from './events/utils'

type EventAttrs = {
  eventLabel?: string
  eventValue?: string | number
}

type UpsellAnalytics = {
  clickedCartUpsellSalePageLink: Function
}

const DEFAULT_CATEGORY = 'Upsell'

const track = (action: string, attrs: EventAttrs = {}): void => {
  analytics.track(DEFAULT_CATEGORY, action, attrs)
}

const upsellAnalytics: UpsellAnalytics = {
  clickedCartUpsellSalePageLink: (saleId: number, slug: string): void => {
    setItemListNamesCookie({
      slug,
      itemListName: 'UpsellClick'
    })

    track('Clicked cart upsells link', {
      eventLabel: `${saleId}`
    })
  }
}

export default upsellAnalytics
