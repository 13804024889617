import { range } from 'ramda'

import Currency from '@atoms/UICurrency/Currency'
import Link from '@atoms/UILink/Link'
import { Item as ItemCart } from '@concepts/Cart/types/item'
import { fetchCartSaleImage } from '@utils/fetchSaleImage'
import { truncate } from '@utils/strings'
import Select from '@atoms/Select/Select'
import Button from '@atoms/UIButton/Button'
import { Box, Flex, Image, Text } from '@lib/UIComponents'

const TEXT_MAX_LENGTH = 68

const styles = {
  itemContainer: {
    backgroundColor: 'grays.41',
    border: '1px solid #A3A6EA',
    display: 'flex',
    flexDirection: 'column',
    py: 4,
    px: 4,
    borderRadius: '4px'
  },
  removeButton: {
    color: 'grays.900',
    height: 'fit-content',
    fontSize: [null, 1]
  },
  priceBoxColumn: {
    display: 'flex',
    mb: 2,
    span: {
      fontSize: [1, 2],
      paddingRight: 4
    }
  },
  price: {
    display: 'block',
    color: 'grays.900'
  },
  originalPrice: {
    display: 'block',
    textDecoration: 'line-through',
    color: 'grays.700'
  },
  actions: {
    display: 'flex',
    alignItems: 'center'
  },
  selectQuantity: {
    minWidth: '78px',
    height: '25px',
    display: 'flex',
    color: 'grays.910',
    select: {
      backgroundColor: 'background',
      border: '1px solid',
      borderColor: 'grays.100',
      borderRadius: '16px',
      minWidth: '78px',
      height: '25px',
      font: 'inherit',
      margin: '0',
      paddingLeft: '40px',
      fontSize: 1
    },
    label: {
      span: {
        fontSize: 1
      }
    }
  }
}

type ItemProps = {
  handleUpdateQuantity: Function
  removeSaleFromCart: Function
  item: ItemCart
}

const Item = ({
  handleUpdateQuantity,
  removeSaleFromCart,
  item: { id, title, sale, quantity, unitPriceCents }
}: ItemProps) => {
  const image = fetchCartSaleImage({
    pictureAttributes: sale.pictureAttributes
  })

  const displayTitle = truncate(title, TEXT_MAX_LENGTH)
  return (
    <Box sx={styles.itemContainer}>
      <Flex sx={{ alignItems: 'center' }}>
        <Link
          sx={{ mr: 6, flexShrink: 0, fontSize: 0 }}
          to={`/sales/${sale.slug}`}
          external
        >
          <Image
            sx={{
              width: ['80px', '100px'],
              height: ['62px', '100px'],
              objectFit: 'cover',
              borderRadius: 'default',
              flexShrink: 0
            }}
            src={image}
            alt={displayTitle}
          />
        </Link>
        <Flex
          sx={{
            flexDirection: 'column',
            fontSize: [1, 2],
            color: 'blacks.400'
          }}
        >
          <Link
            sx={{
              mb: 2,
              fontWeight: 'bold'
            }}
            to={`/sales/${sale.slug}`}
            external
          >
            {displayTitle}
          </Link>

          {sale.isRecurringTrial ? (
            <Text sx={{ fontWeight: 'bold', mt: 1 }}>
              {sale.recurringTrialPeriodDays}-Day Free Trial
            </Text>
          ) : (
            <>
              <Box sx={styles.priceBoxColumn}>
                {sale.retailPriceInCents > unitPriceCents && (
                  <Currency sx={styles.originalPrice}>
                    {sale.retailPriceInCents}
                  </Currency>
                )}

                <Currency sx={styles.price} acceptsZero>
                  {unitPriceCents}
                </Currency>
              </Box>
              <Box sx={styles.actions}>
                <Select
                  id={`quantity-${id}`}
                  data-testid={`quantity-${id}`}
                  optionsList={range(1, sale.maxPerUser + 1)}
                  value={quantity}
                  label="Qty."
                  sx={styles.selectQuantity}
                  labelStyles={styles.selectQuantity.label}
                  onChange={(e) => handleUpdateQuantity(id, e.target.value)}
                />

                <Button
                  variant="link"
                  sx={styles.removeButton}
                  onClick={() => removeSaleFromCart(id)}
                  data-testid={`cart-item-${id}`}
                >
                  Remove
                </Button>
              </Box>
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

export default Item
