import { useEffect } from 'react'
import { curry, pathOr, equals } from 'ramda'
import { Notyf } from 'notyf'

import { Address } from 'src/generated/graphql'
import { Maybe } from 'src/types/maybe'
import { Actions } from '../types/actions'
import { Payment } from '../types/payment'

import { useNotyfContext } from '@atoms/Notyf/context'

import { useCheckoutContext } from '@concepts/Checkout/store/context'

import { Payer } from '@concepts/Paypal/types/payer'
import { Address as PaypalAddress } from '@concepts/Paypal/types/address'

import errorFormatter from '@concepts/Checkout/utils/errorFormatter'
import checkoutFilling from '@concepts/Checkout/utils/checkoutFilling'
import useOrderSuccessful from '@concepts/Checkout/hooks/useOrderSuccessful'

import { updatePurchase } from '@concepts/Purchase/store/actions'
import { PHONE_NUMBER_MISSING_ON_PAYPAL } from '@utils/defaultMessages'
import { isProduction } from '@utils/env'
import { useCartContext } from '@concepts/Cart/store/context'

export const PHONE_NUMBER_FOR_LOWER_ENVS = '+1-202-555-0111'

export type Input = {
  payer: Payer
  purchase_units: Array<{ shipping?: { address?: PaypalAddress } }>
}

const usePaypal = (): {
  checkout: Function
  getPhoneNumber: Function
} => {
  const notyf = useNotyfContext()
  const [
    {
      purchase: { address }
    },
    dispatch,
    { completeCheckout }
  ] = useCheckoutContext()
  const { cart, coupons, credit } = useCartContext()
  const { orderSuccessful } = useOrderSuccessful()

  useEffect(() => {
    if (!isProduction()) {
      console.warn(`
        You're using PayPal SANDBOX environment, the 'checkout' action is going to use a fake phone number.
        PHONE_NUMBER_FOR_LOWER_ENVS: ${PHONE_NUMBER_FOR_LOWER_ENVS}
      `)
    }
  }, [])

  const getPhoneNumber = (phoneNumber: Maybe<string>): Maybe<string> => {
    /*
     * The PayPal SANDBOX does not provide us the phone number from account, for that reason,
     * we're going to use a FAKE one to allow tests.
     */
    return isProduction() ? phoneNumber : PHONE_NUMBER_FOR_LOWER_ENVS
  }

  const getAddress = (
    payer: Payer,
    paypalAddress?: PaypalAddress
  ): { shippingAddressId?: number | null; shippingAddress?: Address } => {
    if (!cart.shippable || !address) {
      return {
        shippingAddress: {
          firstName: payer.name.given_name,
          lastName: payer.name.surname,
          address1: paypalAddress?.address_line_1,
          address2: paypalAddress?.address_line_2,
          countryCode: paypalAddress?.country_code,
          city: paypalAddress?.admin_area_2,
          state: paypalAddress?.admin_area_1,
          zip: paypalAddress?.postal_code,
          phoneNumber: getPhoneNumber(
            payer.phone?.phone_number?.national_number
          )
        } as Address
      }
    }

    if (address.databaseId) return { shippingAddressId: address.databaseId }

    return { shippingAddress: address }
  }

  const getAttributes = curry(
    (payment: Payment, { payer, purchase_units }: Input) => ({
      cartId: cart.id,
      gateway: 'PAYPAL',
      email: payer.email_address,
      paypalParameters: { paypalOrderId: payment.orderID },
      ...getAddress(payer, purchase_units[0]?.shipping?.address),
      ...checkoutFilling({ coupons, credit })
    })
  )

  const getErrors = (errors: Array<Error>): string => {
    const errorPath = pathOr(
      [],
      ['0', 'graphQLErrors', '0', 'problems', '0', 'path'],
      errors
    )

    if (equals(errorPath, ['shippingAddress', 'phoneNumber'])) {
      return PHONE_NUMBER_MISSING_ON_PAYPAL
    }

    return errorFormatter(errors)
  }

  const setResponse = async (attributes: unknown): Promise<void> => {
    const response = await completeCheckout(attributes)

    if (response.success) {
      orderSuccessful({
        order: response.order,
        gtoken: response.gtoken,
        uidocp: response.uidocp,
        gateway: 'PayPal',
        cart
      })
    } else {
      dispatch(updatePurchase({ isSubmitting: false }))
      ;(notyf as Notyf).error({
        message: getErrors(response.errors),
        duration: 0
      })
    }
  }

  const checkout = async (
    payment: Payment,
    actions: Actions
  ): Promise<void> => {
    dispatch(updatePurchase({ isSubmitting: true }))

    return actions.order
      .get()
      .then(getAttributes(payment))
      .then((response: unknown) => {
        setResponse(response)
      })
  }

  return { checkout, getPhoneNumber }
}

export { usePaypal }
