import { Sale } from '@concepts/Cart/types/sale'
import { Sale as GqlSale } from 'src/generated/graphql'

const parse = (sale: Partial<GqlSale>): Sale =>
  ({
    id: sale.databaseId,
    name: sale.name,
    title: sale.title,
    priceInCents: sale.priceInCents,
    retailPriceInCents: sale.retailPriceInCents,
    maxPerUser: sale.maxPerUser,
    maxAvailable: sale.maxAvailable,
    pictureAttributes: sale.pictureAttributes,
    excludeFromCoupons: sale.excludeFromCoupons,
    excludeFromCredits: sale.excludeFromCredits,
    shippable: sale.shippable,
    shippingCostInCents: sale.shippingCostInCents,
    intShippingCostInCents: sale.intShippingCostInCents,
    priceWithDiscountInCents: sale.priceWithDiscountInCents,
    slug: sale.slug,
    recurringInterval: String(sale.recurringInterval),
    recurring: sale.recurring,
    recurringTrialPeriodDays: sale.recurringTrialPeriodDays,
    isRecurringAnnual: sale.recurringInterval === 'annual',
    isRecurringTrial: sale.isRecurringTrial,
    category: sale.category,
    saleGroupOption: sale.saleGroupOption,
    type: sale.type
  }) as Sale

export default { parse }
