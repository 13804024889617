import { equals, toString } from 'ramda'

import { Item } from '@concepts/Cart/types/item'
import { CartItem } from 'src/generated/graphql'
import { Error } from 'src/types/error'

import Sale from './SaleDTO'

import { truncate } from '@utils/strings'

const SALE_TITLE_MAX_LENGTH = 127

const getCartItemErrors = (
  item: CartItem,
  errors?: Array<Error>
): Array<string> => {
  if (!item || !errors) return []

  const isSamePath = (err: Error): boolean =>
    equals(err.path, ['cart', 'item', toString(item.databaseId)] as string[])

  return errors.filter(isSamePath).flatMap((err) => err.message)
}

const parse = (item: CartItem): Item =>
  ({
    id: item.databaseId,
    title: truncate(item.title, SALE_TITLE_MAX_LENGTH),
    quantity: item.quantity,
    unitPriceCents: item.unitPriceCents,
    baseItemId: item.baseCartItemId,
    sale: Sale.parse(item.sale),
    source: item.source
  }) as Item

type ItemFunctionType = (item: CartItem) => Item

const parseWithErrors =
  (errors?: Array<Error>): ItemFunctionType =>
  (item: CartItem): Item => ({
    errors: getCartItemErrors(item, errors),
    ...parse(item)
  })

export default { parse, parseWithErrors }
