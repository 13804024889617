import Link from '@atoms/UILink/Link'
import { Box } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'

type Props = {
  sx?: UIStyles
}

const StackCommerceMenu = (props: Props) => (
  <Box as="ul" data-testid="StackCommerceMenu" {...props}>
    <Box as="li">
      <Link to="https://www.stackcommerce.com/about" external target="_blank">
        About Stack Social
      </Link>
    </Box>
    <Box as="li">
      <Link to="/articles">Expert Picks</Link>
    </Box>
    <Box as="li">
      <Link
        to="https://www.stackcommerce.com/publishers"
        external
        target="_blank"
      >
        Publishers
      </Link>
    </Box>
    <Box as="li">
      <Link
        to="https://partners.stackcommerce.com/affiliates/new"
        external
        target="_blank"
      >
        Affiliates
      </Link>
    </Box>
    <Box as="li">
      <Link to="https://www.stackcommerce.com/vendors" external target="_blank">
        Vendors
      </Link>
    </Box>
    <Box as="li">
      <Link to="https://www.stackcommerce.com/careers" external target="_blank">
        Careers
      </Link>
    </Box>
  </Box>
)

export default StackCommerceMenu
