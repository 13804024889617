import gql from 'graphql-tag'
import { TApolloClient } from 'src/types/apollo'
import { pathOr } from 'ramda'
import { Sale } from 'src/generated/graphql'

const FETCH_RECOMMENDED_PRODUCTS_BY_ORDER = gql`
  query ProductRecommendationsByOrder($orderId: Int!) {
    productRecommendationsByOrder(orderId: $orderId) {
      id
      databaseId
      name
      title
      retailPriceInCents
      priceInCents
      slug
      priceDropEndsAt
      preDropPrice
      preDropPriceInCents
      activeAt
      freeShipping
      newDeal
      priceDrop
      shippable
      shippingCostInCents
      intShippingCostInCents

      categories {
        name
        slug
      }

      imageCollection {
        fourColumnImage {
          pictureAttributes
        }
      }

      ratings {
        averageScore
        totalCount
      }
    }
  }
`

const FETCH_RECOMMENDED_PRODUCTS = gql`
  query ProductRecommendations(
    $limit: Int
    $applyDefaultRules: Boolean
    $onlyDigitalSales: Boolean
    $excludeSaleIds: [Int!]
  ) {
    productRecommendations(
      limit: $limit
      applyDefaultRules: $applyDefaultRules
      onlyDigitalSales: $onlyDigitalSales
      excludeSaleIds: $excludeSaleIds
    ) {
      id
      databaseId
      name
      title
      retailPriceInCents
      priceInCents
      slug
      priceDiscountPercentage
      priceDropEndsAt
      preDropPrice
      preDropPriceInCents
      activeAt
      freeShipping
      newDeal
      priceDrop
      shippable
      shippingCostInCents
      intShippingCostInCents

      categories {
        name
        slug
      }

      imageCollection {
        fourColumnImage {
          pictureAttributes
        }

        cartImage {
          pictureAttributes
        }

        orderConfirmationUpsell {
          pictureAttributes
        }
      }

      ratings {
        averageScore
        totalCount
      }
    }
  }
`

const byOrder = async (
  apolloClient: TApolloClient,
  orderId: number
): Promise<Array<Sale>> =>
  apolloClient
    .query({
      query: FETCH_RECOMMENDED_PRODUCTS_BY_ORDER,
      variables: { orderId }
    })
    .then(pathOr([], ['data', 'productRecommendationsByOrder']))

const search = async (
  apolloClient: TApolloClient,
  params: {
    limit?: number
    applyDefaultRules?: boolean
    onlyDigitalSales?: boolean
    excludeSaleIds?: Array<number>
  }
): Promise<Array<Sale>> =>
  apolloClient
    .query({
      query: FETCH_RECOMMENDED_PRODUCTS,
      variables: params
    })
    .then(pathOr([], ['data', 'productRecommendations']))

export default { byOrder, search }
