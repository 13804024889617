import { TApolloClient } from 'src/types/apollo'
import { removeFromCartRepository } from '../repository/RemoveFromCart'
import { CartActionResult } from '../types/actions'
import { buildError } from '../utils/buildError'

type RemoveItemParams = {
  encryptedCartId: string
  cartItemId: number
  client: TApolloClient
}

const remove = async ({
  encryptedCartId,
  cartItemId,
  client
}: RemoveItemParams): Promise<CartActionResult> => {
  try {
    const removedItem = removeFromCartRepository(
      {
        encryptedCartId,
        cartItemId
      },
      client
    )

    return removedItem
  } catch (error) {
    return Promise.reject(buildError(error as object))
  }
}

export default { remove }
