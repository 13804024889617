import { Box } from '@lib/UIComponents'
import { UIStyles } from '@lib/UIComponents/types'
import { mergeDeepRight } from 'ramda'

type SeparatorProps = {
  sx?: UIStyles
  text?: string
  children?: string
  size?: 'small'
  variant?: 'light'
}

const Separator = ({ text, size, variant, ...props }: SeparatorProps) => {
  const sizes = {
    small: { fontSize: 1 }
  }

  const variants = {
    default: 'grays.600',
    light: 'grays.100'
  }

  const color = variants[variant || 'default']

  const children = props.children || text

  if (!children) {
    return (
      <Box
        as="hr"
        {...props}
        sx={mergeDeepRight(
          {
            my: 5,
            height: '1px',
            border: 'none',
            backgroundColor: color
          },
          props.sx || {}
        )}
      />
    )
  }

  return (
    <Box
      {...props}
      sx={mergeDeepRight(
        {
          display: 'flex',
          alignItems: 'center',
          my: 5,
          color: 'grays.600',
          fontWeight: 'bold',
          textAlign: 'center',
          '::before': {
            content: "''",
            flex: 1,
            mr: 4,
            borderBottom: '1px solid',
            borderColor: color
          },
          '::after': {
            content: "''",
            flex: 1,
            ml: 4,
            borderBottom: '1px solid',
            borderColor: color
          },
          ...(size && sizes[size])
        },
        props.sx || {}
      )}
    >
      {children}
    </Box>
  )
}

export default Separator
