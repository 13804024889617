import Link from '@atoms/UILink/Link'
import { Flex, Text } from '@lib/UIComponents'
import cartAnalytics from '@lib/gtm/events/cart'

const CartEmpty = () => {
  return (
    <Flex>
      <Text sx={{ width: '100%', display: 'block', mb: 4 }}>
        Your cart is empty.{' '}
        <Link
          to="/"
          onClick={() => cartAnalytics.trackContinueShopping()}
          external
        >
          Continue Shopping!
        </Link>
      </Text>
    </Flex>
  )
}

export default CartEmpty
