import ProductRecommendationRepository from '@concepts/ProductRecommendations/repository/ProductRecommendationRepository'
import ProductRecommendationsDTO from '@concepts/ProductRecommendations/dto/ProductRecommendationsDTO'
import { useApollo } from '@lib/apollo/index'
import { useEffect, useState } from 'react'
import { Cart } from '../types/cart'
import { Sale } from 'src/generated/graphql'
import { isPresent } from '@utils/logic'

type useFetchUpsell = {
  isOpen: boolean
  cart: Cart
  quantity: number
}

const useFetchUpsellCartRecommendations = ({
  isOpen,
  cart,
  quantity
}: useFetchUpsell): {
  recommendations: Array<Sale>
} => {
  const apolloClient = useApollo()
  const [recommendations, setRecommendations] = useState<Array<Sale>>([])

  const shouldFetchRecommendations =
    isOpen &&
    quantity &&
    (!isPresent(recommendations) ||
      recommendations.some((r) =>
        cart.items.some((i) => r.databaseId === i.sale.id)
      ))
  const items = cart.items || []
  const currentCartSaleIds = items.map((item) => item.sale.id)
  const excludeSaleIds: Array<number> = cart.upsell
    ? currentCartSaleIds.concat(cart.upsell.databaseId as number)
    : currentCartSaleIds
  const limit = cart.upsell ? 3 : 4

  useEffect(() => {
    if (shouldFetchRecommendations) {
      ProductRecommendationRepository.search(apolloClient, {
        limit,
        excludeSaleIds
      }).then((sales) => {
        const parsedRecommendations = ProductRecommendationsDTO.parse(sales)

        if (cart.upsell) {
          parsedRecommendations.unshift(cart.upsell)
        }

        setRecommendations(parsedRecommendations)
      })
    }
  }, [shouldFetchRecommendations, apolloClient, excludeSaleIds, limit])

  return {
    recommendations
  }
}

export default useFetchUpsellCartRecommendations
