import {
  LinkType,
  loginLink,
  accountLink
} from '@molecules/SiteLinks/SiteLinks'
import noop from '@utils/noop'
import { getQueryString } from '@utils/url'
import { supportLinks } from './supportLinks'

const buildUserAccountLink = (showAccountLink: boolean, userLink: LinkType) => {
  return {
    text: showAccountLink ? 'My Account' : 'Login',
    url: userLink.url,
    onClick: userLink.onClick
  }
}

const twoColumnsCostumerCareLinks: (
  showAccountLink: boolean,
  userLink: LinkType,
  gtoken: string | undefined
) => LinkType[][] = (showAccountLink, userLink, gtoken) => {
  return [
    [
      {
        text: 'My Order',
        url: supportLinks.help(),
        onClick: noop
      },
      {
        text: 'Digital Redemption',
        url: supportLinks.digital(),
        onClick: noop
      },
      buildUserAccountLink(showAccountLink, userLink),
      {
        text: 'Contact Us',
        url: supportLinks.contact(),
        onClick: noop
      },
      {
        text: 'FAQs',
        url: supportLinks.help(),
        onClick: noop
      }
    ],
    [
      {
        text: 'Returns & Refunds',
        url: supportLinks.refunds(),
        onClick: noop
      },
      {
        text: 'Terms & Conditions',
        url: '/terms',
        onClick: noop
      },
      {
        text: 'Accessibility',
        url: '/accessibility',
        onClick: noop
      },
      {
        text: 'Do Not Sell My Personal Information',
        url: `/user${getQueryString({
          show: 'info-request-tab',
          gtoken
        })}`,
        onClick: noop
      }
    ]
  ]
}

const threeColumnsCostumerCareLinks: (
  showAccountLink: boolean,
  userLink: LinkType,
  gtoken: string | undefined
) => LinkType[][] = (showAccountLink, userLink, gtoken) => {
  return [
    [
      {
        text: 'My Order',
        url: supportLinks.help(),
        onClick: noop
      },
      {
        text: 'Digital Redemption',
        url: supportLinks.digital(),
        onClick: noop
      },
      buildUserAccountLink(showAccountLink, userLink)
    ],

    [
      {
        text: 'Returns & Refunds',
        url: supportLinks.refunds(),
        onClick: noop
      },
      {
        text: 'Terms & Conditions',
        url: '/terms',
        onClick: noop
      },
      {
        text: 'Accessibility',
        url: '/accessibility',
        onClick: noop
      }
    ],
    [
      {
        text: 'Contact Us',
        url: supportLinks.contact(),
        onClick: noop
      },
      {
        text: 'FAQs',
        url: supportLinks.help(),
        onClick: noop
      },
      {
        text: 'Do Not Sell My Personal Information',
        url: `/user${getQueryString({
          show: 'info-request-tab',
          gtoken
        })}`,
        onClick: noop
      }
    ]
  ]
}

type Columns = 'two' | 'three'

const OPTIONS = {
  two: twoColumnsCostumerCareLinks,
  three: threeColumnsCostumerCareLinks
}

export default function (
  columnQuantity: Columns,
  isSignedIn: boolean,
  gtoken: string | undefined
): LinkType[][] {
  const showAccountLink = isSignedIn || gtoken
  const userLink = showAccountLink ? accountLink(gtoken) : loginLink()

  return OPTIONS[columnQuantity](Boolean(showAccountLink), userLink, gtoken)
}
