import { Box, Text } from '@lib/UIComponents'
import StackCommerceMenu from './FooterMenu/StackCommerceMenu'

const AboutUs = () => (
  <Box>
    <Text as="p" sx={{ fontSize: [4, '2xl'], fontWeight: 'bold', mb: 4 }}>
      About Us
    </Text>
    <StackCommerceMenu
      sx={{
        li: {
          color: 'grays.400',
          marginBottom: 1,
          a: {
            transition: 'color 250ms',
            '&:hover': {
              color: 'white',
              textDecoration: 'none'
            }
          }
        }
      }}
    />
  </Box>
)

export default AboutUs
