import { ActionsTypes } from './actionTypes'
import { Cart } from '../types/cart'
import { Action } from '../types/actions'

const setCart = (cart: Cart): Action => ({
  type: ActionsTypes.SET_CART,
  cart
})

const addCouponCode = (couponInput: string): Action => ({
  type: ActionsTypes.ADD_COUPON_CODE,
  couponInput
})

const removeCouponCode = (couponInput: string): Action => ({
  type: ActionsTypes.REMOVE_COUPON_CODE,
  couponInput
})

const setLoading = (isLoading: boolean): Action => ({
  type: ActionsTypes.SET_LOADING,
  isLoading
})

export { setCart, addCouponCode, removeCouponCode, setLoading }
