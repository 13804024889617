import segmentAnalytics from '@lib/segment/analytics'
import { CartActionResult } from '@concepts/Cart/types/actions'
import { setItemListNamesCookie } from '@lib/gtm/events/utils'

type Params = {
  operationResult: CartActionResult
  hostname?: string
  fromUpsell?: boolean
}

const productAddedToCartAnalytics = ({
  operationResult,
  hostname,
  fromUpsell
}: Params): void => {
  const { cartItem } = operationResult

  if (!cartItem) return

  if (fromUpsell) {
    setItemListNamesCookie({
      itemListName: 'UpsellClick',
      slug: cartItem.sale.slug
    })
  }

  if (hostname) {
    segmentAnalytics.productAdded({
      cartItem,
      cartId: cartItem.cartId,
      hostname
    })
  }
}

export { productAddedToCartAnalytics }
