import { Actions } from '@concepts/Paypal/types/actions'

const patch = (
  attribute: string,
  value: object,
  actions: Actions
): { op: string; path: string; value: object } => {
  return actions.order.patch([
    {
      op: 'replace',
      path: "/purchase_units/@reference_id=='default'/" + attribute,
      value
    }
  ])
}

export default { patch }
