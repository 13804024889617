import { Cart, CartState } from '@concepts/Cart/types/cart'
import { ActionsTypes } from '@concepts/Cart/store/actionTypes'
import { pathOr } from 'ramda'
import { Action } from '../types/actions'

const initialState: CartState = {
  isLoading: true,
  cart: {
    summary: {},
    items: [],
    hasRecurringSale: false,
    hasCannabidiolSale: false
  } as unknown as Cart,
  coupons: {
    codes: []
  },
  credit: {
    isApplied: false,
    payEntirelyWithCredits: false
  }
}

const reducer = (state: CartState, action: Action): CartState => {
  switch (action.type) {
    case ActionsTypes.SET_LOADING: {
      return { ...state, isLoading: action.isLoading }
    }
    case ActionsTypes.SET_CART: {
      const cart = { ...initialState.cart, ...action.cart } as Cart
      const isEmpty = pathOr([], ['items'], cart).length === 0

      const {
        shippable,
        summary: { totalInCents, creditsInCents }
      } = cart
      const hasTaxes = totalInCents > 0
      const hasCredits = creditsInCents > 0

      return {
        ...state,
        cart: { ...cart, isEmpty },
        credit: {
          isApplied: hasCredits,
          payEntirelyWithCredits: hasCredits && !hasTaxes && !shippable
        }
      }
    }
    case ActionsTypes.ADD_COUPON_CODE: {
      if (state.coupons.codes.find(({ code }) => code === action.couponInput))
        return state
      return {
        ...state,
        coupons: {
          ...state.coupons,
          codes: [...state.coupons.codes, { code: action.couponInput }]
        }
      }
    }
    case ActionsTypes.REMOVE_COUPON_CODE: {
      const filteredCoupons = state.coupons.codes.filter(
        (coupon) => coupon.code !== action.couponInput
      )
      return {
        ...state,
        coupons: { ...state.coupons, codes: filteredCoupons }
      }
    }
    default:
      return state
  }
}

export { initialState, reducer }
