import { ChevronDownIcon } from '@atoms/Icon/Icon'
import { mergeDeepRight, omit } from 'ramda'
import Error from '@atoms/Error/Error'
import { Props } from './types'
import Options from './Options'
import { styles } from './styles'
import { Box, Label } from '@lib/UIComponents'

const Select = ({
  optionsList,
  label,
  labelIdentifier = 'label',
  valueIdentifier = 'value',
  labelStyles = {},
  arrowIconStyles = {},
  customArrowIcon = null,
  sx = {},
  error = '',
  ...props
}: Props) => {
  return (
    <>
      <Box sx={styles.container}>
        {label && (
          <Label
            htmlFor={props.id}
            sx={mergeDeepRight(styles.label(), labelStyles as object)}
          >
            {label}
          </Label>
        )}
        <Box
          sx={mergeDeepRight(styles.selectBox(Boolean(error)), sx as object)}
        >
          <select {...omit(['error'], props)}>
            <Options
              optionsList={optionsList}
              labelIdentifier={labelIdentifier}
              valueIdentifier={valueIdentifier}
            />
          </select>
        </Box>
        <Box sx={mergeDeepRight(styles.arrowIcon, arrowIconStyles as object)}>
          {customArrowIcon || <ChevronDownIcon />}
        </Box>
      </Box>

      {error && <Error message={error} />}
    </>
  )
}

export default Select
